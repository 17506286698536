import React, { useState, useEffect } from 'react';
import ProductCard from '../components/ProductCard';
import InfiniteScroll from "react-infinite-scroll-component";
import Meta from '../components/Meta';

const VipProducts = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);

  useEffect(() => {
    ProductData();
  },);

  const handleNextPage = () => {
    if (hasMorePages) {
        ProductData();
    }
  };

  const ProductData = async () => {

    try {
      const response = await fetch(
       `https://rentermmc.com:8443/api/Product/get-promotions?LanguageCode=az&pageIndex=${page}&PromotionType=vip`
       
      );

      const data = await response.json();
      if (data.data.items.length > 0) {
        setProducts(prevProducts => [...prevProducts, ...data.data.items]);
        setHasMorePages(true);
        setPage(page + 1);
      } else {
        setHasMorePages(false);
      }
    
    } catch (error) {
      setHasMorePages(false);
      console.error('Error fetching data:', error);
    }
    
  };

  return (
<div className='container_m'>
<div className='container_n'>
<Meta title="VİP Elanlar Renter MMC"/>

    <div className='row'>
      <div className='col-12'>

      <InfiniteScroll
                  dataLength={products.length}
                  next={handleNextPage}
                  hasMore={hasMorePages}
                  loader={<div className='text-center my-4'>Yüklənir</div>}
                  endMessage={<div className="text-center my-4">Göstəriləcək daha çox məlumat yoxdur</div>}
                  className="custom-infinite-scroll"
                  scrollThreshold={0.5} >
                    <ProductCard products={products} isLoading={false}/>
                </InfiniteScroll>
    

      </div>
    </div>
   </div>
   </div>


  )
}

export default VipProducts;
