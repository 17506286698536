import React, { useState, useEffect, useRef } from 'react';
import { BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInstance';
import Meta from '../components/Meta';


const UpdateProduct = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState('');
  const [paramValues, setParamValues] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parameterValues, setParameterValues] = useState({});
  const [subParamValues, setSubParamValues] = useState();

  const fileInputRef = useRef(null);


  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[2];

  const [productTitle, setProductTitle] = useState(productData.productTitle);
  const [description, setDescription] = useState(productData.productDescription);

  const handleProductTitleChange = (event) => {
    setProductTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    fetchParameters();
  }, []);


  // Fetch parameters data
  const fetchParameters = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Product/product-update?LanguageCode=az&ProductSlug=${slug}`
      );

      const data = response.data;
      //const response = await fetch(fetchUrl);
      //const data = await response.json();
      setProductData(data.data);
      setImageUrls(data.data.images)
      setParamValues(data.data.parameters);
      setProductTitle(data.data.productTitle);
      setDescription(data.data.productDescription)

    } catch (error) {
      console.error('Error fetching parameters:', error);
    }
  };

  // Handle file input change and upload
  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    try {
      const uploadedImageUrl = await uploadFile(selectedFile);
      setImageUrls([...imageUrls, uploadedImageUrl]);
      setLoading(false); // Image uploaded, so set loading to false
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false); // Set loading to false in case of an error
    }
  };


  // Upload the file to the server
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('files', file);
      const response = await fetch('https://rentermmc.com:8443/api/Product/add-image', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      const uploadedImageUrl = data.data;
      
      console.log('File uploaded successfully');
      return uploadedImageUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

 // Handle form submission
const handleFormSubmit = async () => {
  const formAdd = document.getElementById('updateForm'); 
  const formData = new FormData(formAdd);
  const data = {
    parameters: {},
  };
 console.log(formAdd);

  let phoneValue; 


  for (let [name, value] of formData.entries()) {
    if (name === 'ProductTitle' || name === 'CategoryId' || name === 'Description' || name === 'fullName' || name === 'email' || name === 'phone') {
      data[name] = value;
      if (name === 'phone') {
        phoneValue = value; // Save the value of the "phone" input
      }
    } else if (name === 'files') { } else {
      const paramName = name;
      const paramValue = value;
      data.parameters[paramName] = paramValue;
    }
  }

  data['images'] = imageUrls;
  
  data['productId'] = productData.productId;
  
  console.log('Data to be submitted:', data);

  const token = localStorage.getItem('token');

  if (token) {

  try {
    const response = await axiosInstance.post('api/Product/product-update', data);

    if (response.status === 200) {
      console.log('Data submitted successfully');
      navigate('/myproducts'); 
    } else {
      console.error('Post request failed with status:', response.status);
    }
  } catch (error) {
    console.error('Error sending data:', error);
  }

 }  else {
/*  codeRequest(phoneValue) 

  console.log(phoneValue,'nomre');
  toggleModal();
  */
  }



};

const submitForm = () => {
  handleFormSubmit();
};
  const handleDeleteImage = (imageUrl) => {
    setImageUrls((prevImageUrls) => prevImageUrls.filter((url) => url !== imageUrl));
  };

  // Generate parameter inputs based on the fetched paramValues
  useEffect(() => {
    const initialParameterValues = {};
    paramValues.forEach((param) => {
      initialParameterValues[param.parameterKey] = param.parameterValue || ''; // Set initial values
    });
    setParameterValues(initialParameterValues);
  }, [paramValues]);

  // Create an onChange handler for parameter inputs
  const handleParameterChange = (event) => {
    const { name, value } = event.target;
    setParameterValues({
      ...parameterValues,
      [name]: value,
    });
  };

  const handleParameterChangeSelect = (event) => {
    const parameterKey = event.target.name;
    const mask = event.target.value;
    fetchSubParameters(parameterKey, mask);
    const { name, value } = event.target;
    setParameterValues({
      ...parameterValues,
      [name]: value,
    });
  };

  // Fetch Parameters Data
  const fetchSubParameters = async (parameterKey, mask) => {
    try {
      let fetchUrl = `https://rentermmc.com:8443/api/Category/get-child-masks?LanguageCode=az&ParameterKey=${parameterKey}&Mask=${mask}`;

      const response = await fetch(fetchUrl);
      const data = await response.json();
      setSubParamValues(data.data);
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  useEffect(() => {
    if (subParamValues && subParamValues.parameterMasks) {
      const maskOptions = subParamValues.parameterMasks.map((mask) => mask.parameterMaskData);
      const selectElement = document.getElementById(subParamValues.parameterKey);
      selectElement.innerHTML = '';
  
      const defaultOption = document.createElement('option');
      defaultOption.value = '';
      defaultOption.textContent = `Seçim Edin`;
      selectElement.appendChild(defaultOption);
  
      maskOptions.forEach((mask) => {
        const option = document.createElement('option');
        option.value = mask;
        option.textContent = mask;
        selectElement.appendChild(option);
      });
    }
  }, [subParamValues]);
  // ...

  // Generate parameter inputs based on the fetched paramValues
  const renderParameterInputs = () => {
    return paramValues.map((param) => {
      return (
        <React.Fragment key={param.parameterId}>
          <div className='col-4 mt-3'>
            <label>{param.parameterTitle}</label>
          </div>
          <div className='col-8 mt-3'>
            {param.parameterMasks !== null && param.parameterMasks.length > 0 ? (
              <select
                className='form-control mb-2'
                name={param.parameterKey}
                value={parameterValues[param.parameterKey] || ''}
                id={param.parameterKey}
                onChange={handleParameterChangeSelect} // Add onChange handler
              >
                <option value=""> {param.parameterTitle} seçin</option>
                {param.parameterMasks.map((mask) => (
                  <option
                    key={mask.parameterMaskId}
                    value={mask.parameterMaskData}
                    data-key={mask.parameterMaskId}
                    selected={mask.isSelected ? true : false}
                  >
                    {mask.parameterMaskData}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className='form-control mb-2'
                type={param.parameterTypeTitle === 'int' ? 'number' : 'text'}
                step=".01"
                name={param.parameterKey}
                value={parameterValues[param.parameterKey] || ''}
                onChange={handleParameterChange} // Add onChange handler
              />
            )}
          </div>
        </React.Fragment>
      );
    });
  };

  return (
    <div className='container_m'>
      <div className='container_n mt-3 mb-3'>
      <Meta title="Elan yenilə Renter MMC"/>

        <h1>Yeni elan</h1>
        <div className='card mt-3 mb-5'>
          <div className='card-body'>
            <form id="updateForm">
              <div className='row'>
                <div className='col-7'>
                  <div className='row'>
              
                
                    {renderParameterInputs()}
                    <div className='col-4 mt-2'>
                      <label>Elan adı*</label>
                    </div>
                    <div className='col-8 mt-2'>
                      <input
                        className='form-control'
                        name='ProductTitle'
                        value={productTitle}
                        onChange={handleProductTitleChange} // Add onChange handler
                      />
                    </div>

                    {/* Description textarea */}
                    <div className='col-4 mt-3'>
                      <label>Məzmun</label>
                    </div>
                    <div className='col-8 mt-3'>
                      <textarea
                        className='form-control'
                        name='Description'
                        value={description}
                        onChange={handleDescriptionChange} // Add onChange handler
                      ></textarea>
                    </div>
                    <div className='col-4 mt-3'>
                      <label>Şəkillər</label>
                    </div>
                    <div className='col-8 mt-3'>
                      <button type='button' className='btn btn-light' onClick={handleButtonClick}>Şəkil əlavə et</button>
                      <input
                        ref={fileInputRef}
                        accept="image/*"
                        type="file"
                        name="files"
                        onChange={handleFileChange}
                        className="d-none"
                      />

                      <div className='row mt-3' id='image-container'>
                        {console.log(imageUrls,'budur')}
                        {imageUrls.map((imageUrl) => (
                          <div className='col-6'>
                            <div className='img_upload_box text-center'>
                              <img src={imageUrl} key={imageUrl} width={100} className='img_upload_box_img' />
                              <div className='img_upload_box_delete'>
                                <button type='button' className='btn btn-danger mt-2' onClick={() => handleDeleteImage(imageUrl)}>
                                  <BsTrash />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        {loading && (
                          <div className='col-6'>
                            <div className='img_upload_box text-center'>
                       Yüklənir
                            </div>
                          </div>
                        )}

                      </div>

                    </div>
                    <div className='col-12 text-center mt-3'>
                      <hr />
                      Elan yerləşdirərək, siz istifadəçi razılaşması ilə razı olduğunuzu təsdiq edirsiniz
                    </div>
                    <div className='col-12 text-center mt-3'>
                      <button type='button'  onClick={submitForm} className='btn btn-primary'>
                        Elanı yenilə
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-5'>
                  <p>
                    <b>Qaydalar</b>
                  </p>
                  <p>Eyni elanı bir neçə dəfə təqdim etməyin.</p>
                  <p>Təsvir və ya şəkillərdə telefon, email və ya sayt ünvanı göstərməyin.</p>
                  <p>Ad yerində qiymət yazmayın - bunun üçün ayrıca yer var.</p>
                  <p>Qadağan olunmuş məhsulları satmayın.</p>
                  <p>Saytın tam qaydaları</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;