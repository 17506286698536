import React, { useState, useEffect } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import axiosInstance from '../axiosInstance';
import { Link } from "react-router-dom";
import { ReactComponent as Friday } from "../Images/friday.svg";
import { ReactComponent as View } from "../Images/viewnumber.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const ProductCard = ({ products, lang }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [likedProducts, setLikedProducts] = useState([]);

  useEffect(() => {
    const storedLikedProducts = localStorage.getItem("likedProducts");
    if (storedLikedProducts) { setLikedProducts(JSON.parse(storedLikedProducts)); }
  }, []);


  const showDeleteConfirmation = (slug) => {
    confirmAlert({
      title: 'Elan silinsinmi?',
      message: 'Elan silindikdən sonra əməliyyat geri alınmayacaq',
      buttons: [
        {
          label: 'Bəli',
          onClick: () => handleDelete(slug),
        },
        {
          label: 'Xeyr',
          onClick: () => { },
        },
      ],
    });
  };

  const handleDelete = async (slug) => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Product/delete-product?Slug=${slug}`
      );
      const data = response.data;
      if (data.statusCode == 200) {
        toast.success(data.messages[0]);
      } else {
        toast.error(data.messages[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.messages) {
        toast.error(error.response.data.message);
      } else {
        console.log(error.message);
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <div className='col-12 col-xs-12 col-md-12 col-lg-12'>
        <div className="row">
          {products.map((product, index) => (
            <div className="col-6 col-sm-6 col-xs-6 col-md-6 col-lg-4 col-xl-4" key={index} >
              <div className="product_card">

                <Link to={`/updateproduct/${product.slug}`} className="edit_btn">
                  <FaPencilAlt />
                </Link>

                {product.productStatus == 2 && (
                  <Link onClick={() => showDeleteConfirmation(product.slug)} className="delete_btn">
                    <FaTrashAlt />
                  </Link>
                )}


                <div className="product_card_photo">
                  <Link to={`/product/${product.slug}`} className="w_100_f w-100">
                    <img src={product.coverImage} alt="product" className="product_card_photo_img mouse w-100" />
                  </Link>
                  <div className="promotions">
                  {product.promotionIcons.map((icon, index) => (
  icon && (
    <img src={icon} alt="promotion" className="promotion_icon" key={index} width={30} />
  )
))}

                  </div>
                </div>

                <div className="product_card_body">
                  <Link to={`/product/${product.slug}`} className="link w-100">
                    <div className="product_card_price d-flex justify-content-between align-items-center">
                      <div>{product.price}  <small> AZN</small> </div>
                      <div className="product-dates d-flex align-items-center"><Friday className="me-2" />1 gün</div>
                    </div>
                    <div className="product_card_title  d-flex justify-content-between align-items-center">
                      {product.productTitle.length <= 15 ? product.productTitle : `${product.productTitle.slice(0, 15)}...`}
                      <img height={30} src={product.paymentIcon} />
                    </div>
                    <div className="product_card_created d-flex align-items-center justify-content-between"><div> {product.city}</div>{/*<div className="d-flex align-items-center"><View className="me-1" /><span className="only_desk me-1">Baxış sayı : </span> {product.viewCount}</div>*/}</div>
                  </Link>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductCard