import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import axios from "axios";
import { ReactComponent as Call } from "../Images/phone.svg";
import { ReactComponent as Location } from "../Images/location.svg";
import { FaHeart,FaFlag,FaTrashAlt,FaMapMarkerAlt,FaPhoneAlt,FaRegClock } from "react-icons/fa";
import ProductCard from '../components/ProductCard';
import axiosInstance from '../axiosInstance';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Meta from "../components/Meta";

const SingleProduct = () => {
  const [product, setProduct] = useState({});
  const [similarproduct, setSimilarProduct] = useState([]);
  const [likedProducts, setLikedProducts] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[2];
  const [isReportOpen, setIsReportOpen] = useState(false);

  const toggleReport = () => { setIsReportOpen(!isReportOpen) };

  const [reportMessage, setReportMessage] = useState('');

  const handleReportMessageChange = (event) => {
    setReportMessage(event.target.value);
  }


  const handleReportSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = {};
    data["productSlug"] = slug;
    data["reportContent"] = reportMessage;


    try {
      const response = await axiosInstance.post('https://rentermmc.com:8443/api/Report/add-report', data);

      if (response) {
        setReportMessage("");
        setIsReportOpen(false);
        toast.success("Şikayətiniz uğurla göndərildi");
      } else {
        console.error('Post request failed with status:', response.status);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    getProduct();
    getSimailarProduct()
    // localdan getirmek
    const storedLikedProducts = localStorage.getItem("likedProducts");
    if (storedLikedProducts) {
      setLikedProducts(JSON.parse(storedLikedProducts));
    }
  }, [slug]);

  const getProduct = async () => {
    try {
      const response = await axios.get(
        `https://rentermmc.com:8443/api/Product/get-product?LanguageCode=az&Slug=${slug}`
      );
      setProduct(response.data.data);
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };


  const getSimailarProduct = async () => {
    try {
      const response = await axios.get(
        `https://rentermmc.com:8443/api/Product/get-similar-products?LanguageCode=az&Slug=${slug}`
      );
      setSimilarProduct(response.data.data);
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };



  const handleLike = (productId) => {
    let updatedLikedProducts;

    if (likedProducts.includes(productId)) {
      updatedLikedProducts = likedProducts.filter((id) => id !== productId);
    } else {
      updatedLikedProducts = [...likedProducts, productId];
    }

    setLikedProducts(updatedLikedProducts);
    localStorage.setItem("likedProducts", JSON.stringify(updatedLikedProducts));
  };

  // Lightbox image URLs
  const productImages = [
    product.coverImage,
    ...(product.productGalleries || []).map((gallery) => gallery.productGalleryFile),
  ];

  // Event handler for opening the lightbox
  const openLightbox = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };
  // Render the lightbox component
  const renderLightbox = () => {
    if (!lightboxOpen) return null;
    return (
      <Lightbox
        mainSrc={productImages[lightboxIndex]}
        nextSrc={productImages[(lightboxIndex + 1) % productImages.length]}
        prevSrc={
          productImages[
          (lightboxIndex + productImages.length - 1) % productImages.length
          ]
        }
        onCloseRequest={() => setLightboxOpen(false)}
        onMovePrevRequest={() =>
          setLightboxIndex(
            (lightboxIndex + productImages.length - 1) % productImages.length
          )
        }
        onMoveNextRequest={() =>
          setLightboxIndex((lightboxIndex + 1) % productImages.length)
        }
      />
    );
  };
  console.log("Product state:", product);

  return (
    <div className="container_m">
    <div className="container_n mb-5">

    <Meta title={product.productTitle}/>


    <ToastContainer  /> 



{product?.storeDetailModel?.storeSlug  && (
<div className="store_card mt-3">
            <div className='only_desk p-2'>
              <div className='row'>
                <div className='col-12 col-md-6 d-flex justify-content-end align-items-center'>
                    <div className='col-md-12 text-end '>
                      <div className='row my-2'>
                        <div className='col-md-9'>
                          <div className='color_w p-2'>{product?.storeDetailModel?.storeAddress}</div>
                        </div>
                        <div className='col-md-3'>
                          <div className='store_card_icon_box p-2 text-start'><FaMapMarkerAlt className='fs-3' /></div>
                        </div>
                      </div>
                      <div className='row my-2'>
                        <div className='col-md-9'>
                        <div className='color_w p-2'>{product?.storeDetailModel?.storePhones}</div>
                        </div>
                        <div className='col-md-3'>
                        <div className='store_card_icon_box p-2 text-start'><FaPhoneAlt className='fs-3' /></div>
                        </div>
                      </div>
                      <div className='row my-2'>
                        <div className='col-md-9'>
                        <div className='color_w p-2'>{product?.storeDetailModel?.workHours}</div>
                        </div>
                        <div className='col-md-3'>
                        <div className='store_card_icon_box p-2 text-start'><FaRegClock className='fs-3' /></div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 text-end'>
                  <img src={product?.storeDetailModel?.storeCover} width={200} height={200} className="store_card_img_top" alt={product?.storeDetailModel?.storeTitle} />
                  <h5 className="store_card_title mt-3">{product?.storeDetailModel?.storeTitle}</h5>
                  <p className="card-text">{product?.storeDetailModel?.storeDescription}</p>
                </div>

                </div>
              </div>


              <div className="only_mobile p-2">
              <div className='row'>
                <div className='col-12'>
                      <div className='row'>
                        <div className='col-6 d-flex justify-content-end align-items-center'>

                            <h4>{product?.storeDetailModel?.storeTitle}</h4>
                        </div>
                        <div className='col-6 text-end'>
                        <img src={product?.storeDetailModel?.storeCover} width={150} height={150} className="store_card_img_top" alt={product?.storeDetailModel?.storeTitle} />
                        </div>
                      </div>
                      <div className='col-12  text-end'>
                        <div className='mt-2'><h5>{product?.storeDetailModel?.storeDescription}</h5></div>
                        <div className='p-2'>{product?.storeDetailModel?.storeAddress} <FaMapMarkerAlt className='fs-3' /></div>
                        <div className='p-2'>{product?.storeDetailModel?.storePhones} <FaPhoneAlt className='fs-3' /></div>
                        <div className='p-2'>{product?.storeDetailModel?.workHours} <FaRegClock className='fs-3' /></div>
                      </div>

                  </div>
                </div>
    
              </div>


            </div>



)}


      
      <div className="row">
        <div className="col-12">
          <div className="row d-flex">
            <div className="col-12 col-md-8 order-1 mb-3">
              <div className="main-product-image">
                <div className="w-100" onClick={() => openLightbox(0)}>

                  <img src={product.coverImage} alt="main-img" />
                </div>
              </div>

              {renderLightbox()}

            </div>
            <div className="col-12 col-md-4 order-5 order-lg-2 single-user-details mb-3">
              <h2>{product?.user?.userFirstName} {product?.user?.userLastName}</h2>
              <hr></hr>
              <div className="d-flex flex-column justify-content-center">
                <p className="d-flex align-items-center"><Call className="me-2" />
                <Link to={`tel:${product?.user?.userPhone}`}>
                {product?.user?.userPhone}
                </Link>
</p>

              </div>
              <hr></hr>
              <div className="d-flex flex-column align-items-center justify-content-center h-25">
              <Link  to={`/productvipup/${slug}`}>
                <button className="buy-vip mt-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="31" viewBox="0 0 29 31" fill="none">
                    <path d="M5.88772 3.875H23.1113C23.302 3.87497 23.4899 3.92317 23.6598 4.01566C23.8297 4.10816 23.9767 4.24231 24.0889 4.40717L28.7083 11.1962C28.7904 11.317 28.8309 11.4645 28.8227 11.6135C28.8146 11.7626 28.7583 11.904 28.6636 12.0138L14.943 27.9026C14.8864 27.9679 14.8178 28.0201 14.7415 28.0557C14.6652 28.0914 14.5828 28.1098 14.4995 28.1098C14.4162 28.1098 14.3338 28.0914 14.2575 28.0557C14.1812 28.0201 14.1126 27.9679 14.0561 27.9026L0.335432 12.0151C0.240398 11.9052 0.183986 11.7635 0.175831 11.6142C0.167677 11.4648 0.208287 11.3171 0.290723 11.1962L4.91018 4.40717C5.02229 4.24231 5.1693 4.10816 5.3392 4.01566C5.5091 3.92317 5.69706 3.87497 5.88772 3.875Z" fill="#8A53FE" />
                  </svg>Elanı VIP et
                </button>
                </Link>
                <div className=" mb-5">
                  <Link to={`/search/?UserCode=${product?.user?.userCode}`}>
                    Satıcının bütün elanlarını gör
                  </Link></div></div>
            </div>

            <div className="col-12 order-2 order-lg-3 col-md-12 ">

              <div className="other-product-images">
                {product.productGalleries && product.productGalleries.length > 0 ? (
                  product.productGalleries.map((gallery, index) => (
                    <div
                      onClick={() => openLightbox(index + 1)}
                      key={gallery.productGalleryId}
                      className="gallery-item"
                      style={{ backgroundImage: `url(${gallery.productGalleryFile})` }}
                    ></div>
                  ))
                ) : (
                  <p>No gallery images available</p>
                )}
              </div>
            </div>

            <div className="col-12 col-md-12 order-3 order-lg-4 order-lg-5">
              <div className="single-product-details">
                <div className="single-product-price col-3 col-md-3">
                  {product.price} AZN
                </div>
                <div className="single-product-title col-9 col-md-6">
                  <span>{product.productTitle}</span>
                </div>
                <div className="single-product-views col-3 col-md-3 p-3">
                  {/*<div>Baxışların sayı: {product.viewCount}</div>*/}
                  <div>Elanın nömrəsi: {product.productId}</div>
                </div>
              </div>
            </div>

{/* Product Parametrs and description start*/}

            <div className="col-12 col-md-6 order-4 order-lg-5 order-md-5 my-3">
              {product.parameters && product.parameters.length > 0 && (
                <div className="single-parametrs-details">
                  {product.parameters.map((parameter, index) => (
                    <div className="detail-key-value text_cap" key={index}>
                      <div className="row">
                        <div className="col-6">
                          <span className="detail-key">{parameter.parameterTitle}:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            {parameter.parameterValue}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="col-12 col-md-6 order-4 order-lg-5 order-md-5 my-3">
              <div className="single-parametrs-details p-3">
                  <p>{product.productDescription}</p>
              </div>
              <div className="single-parametrs-short-cats p-3">
              <div className="detail-key-value">
              <span className="detail-key"  role="button" onClick={() => handleLike(product.slug)} > 
               <FaHeart color={ likedProducts.includes(product.slug) ? "red" : "black" }/>  Bəyənilənlərə əlavə et </span>
                  </div>
                  <div className="detail-key-value">
                  <span className="detail-key" role="button" onClick={toggleReport}>   <FaFlag/> Şikayət et </span>


                  {isReportOpen && (

<div className="col-12">
                  <form className="w-100" onSubmit={handleReportSubmit}>
  <textarea
    className="mt-3 w-100 color_b"
    placeholder="Şikayətinizi daxil edin..."
    required
    value={reportMessage}
    onChange={handleReportMessageChange}

  ></textarea>
  <br /><div className='col-12 text-end'>  <input className="btn btn-danger mt-1" type="submit" name="submitInfo" value="Göndər" />
</div>
</form> 
</div>
)}

                  </div>
                  <div className="detail-key-value">
                  </div>
            </div>
            </div>

{/* Product Parametrs and description stop*/}

          </div>
        </div>

        <ProductCard products={similarproduct} isLoading={false}/>


      </div>
    </div>
    </div>
  );
};

export default SingleProduct;
