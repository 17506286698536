import React, { useState, useEffect } from "react";
import UserDetails from "../components/UserDetails";
import axiosInstance from '../axiosInstance';
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ReactComponent as Avatar } from "../Images/avatar.svg";
import { ReactComponent as Mail } from "../Images/mail.svg";
import { ReactComponent as Call } from "../Images/phone.svg";
import Meta from "../components/Meta";

const ProfileDetails = () => {

  const [profileData, setProfileData] = useState([]);
  
  const [userFirstName, setUserFirstName] = useState([]);
  const [userLastName, setUserLastName] = useState([]);
  const [userName, setUserName] = useState([]);
  const [userPhone, setUserPhone] = useState([]);

  const handleUserFirstNameChange = (event) => {
    setUserFirstName(event.target.value);
  };

  const handleUserLastNameChange = (event) => {
    setUserLastName(event.target.value);
  };

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleUserPhoneChange = (event) => {
    setUserPhone(event.target.value);
  };



  const getProfileData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/auth/get-user-info-by-token`
      );
      const data = response.data;
      setProfileData(data.data);
      setUserFirstName(data.data.userFirstName)
      setUserLastName(data.data.userLastName)
      setUserName(data.data.userName)
      setUserPhone(data.data.userPhone)

      
    } catch (error) {
      console.error('Error fetching shortCuts items:', error);
    }
  };


  useEffect(() => {
    getProfileData();
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = {};

    data["userFirstName"] = userFirstName;
    data["userLastName"] = userLastName;
    data["userName"] = userName;
    data["userPhone"] = userPhone;

    try {
      const response = await axiosInstance.post('https://rentermmc.com:8443/api/auth/update-user-info', data);

      if (response) {
        toast.success(response.data.message);
      } else {
        console.error('Post request failed with status:', response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 500 && error.response.data.messages) {
        toast.error(error.response.data.message);
      } else {
        console.log(error.message);
      }
    }
  };
  


  return (
    <div className="container_m">
      <div className="container_n">
      <Meta title="Profil Renter MMC"/>

      <ToastContainer  /> 
      <div className="col-12">
        <div className="row">
            <UserDetails />
            <div className="col-12 col-lg-9 my-3 px-2">
            <div className="details-description">

              <div className="row p-3 profile-details-title">
                Şəxsi Məlumatlarım
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row p-3 profile-details-inner d-flex">
                  <div className="col-12 col-md-6">
                    <div className="position-relative">
                      <label for="userFirstName" className="login-labels">
                        Ad*
                      </label>
                      <input
                        type="text"
                        className="login-email"
                        id="userFirstName"
                        value={userFirstName}
                        onChange={handleUserFirstNameChange}
                      />
                    </div>
                    </div>



                    <div className="col-12 col-md-6">
                      <div className="position-relative">
                        <label for="userLastName" className="login-labels">
                          Soyad*
                        </label>
                        <br></br>
                        <input
                          type="text"
                          className="login-email"
                          id="userLastName"
                          value={userLastName}
                          onChange={handleUserLastNameChange}
                        />                      </div>
                      </div>








                    <div className="col-12 col-md-6">
                    <div className="position-relative">
                      <label for="userName" className="login-labels">
                        İstifadəçi adı*
                      </label>
                      <input
                        type="text"
                        className="login-email"
                        id="userName"
                        value={userName}
                        onChange={handleUserNameChange}
                      />
                    </div>
                    </div>


                      <div className="col-12 col-md-6">

                      <div className="position-relative">
                        <label for="userPhone" className="login-labels">
                          Əlaqə nömrəsi *
                        </label>
                        <input
                          type="tel"
                          className="login-email"
                          id="userPhone"
                          name="userPhone"
                          value={userPhone}
                          onChange={handleUserPhoneChange}
                        />
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end align-items-center">
                
                      <NavLink to="reset-password"><button className="btn btn-light me-3">Şifrəni yenilə</button></NavLink>
                      <button type="submit" className="primary_btn">Redaktə et</button>
                    </div>
                  </div>
                
              </form>
            </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

export default ProfileDetails;
