import React, { useState, useEffect } from "react";
import UserDetails from "../components/UserDetails";
import axiosInstance from '../axiosInstance';
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import Meta from "../components/Meta";

const ProfileDetails = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = {};
    for (let [name, value] of formData.entries()) {
      data[name] = value;
    }

    try {
      const response = await axiosInstance.post('https://rentermmc.com:8443/api/auth/update-user-password', data);

      if (response) {
        toast.success(response.data.message);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
  
        navigate("/");
      } else {
        console.error('Post request failed with status:', response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 500 && error.response.data.messages) {
        toast.error(error.response.data.message);
      } else {
        console.log(error.message);
      }
    }
  };
  


  return (
    <div className="container_m">
      <div className="Şifrəni yenilə container_n">
      <Meta title="Əlaqə Renter MMC"/>

      <ToastContainer  /> 
      <div className="col-12">
        <div className="row">
            <UserDetails />
            <div className="col-12 col-lg-9 my-3 px-2">
            <div className="details-description">

              <div className="row p-3 profile-details-title">
                Şifrəni yenilə
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row p-3 profile-details-inner d-flex">
                  <div className="col-12 col-md-6">
                    <div className="position-relative">
                      <label for="oldPassword" className="login-labels">
                        Köhnə şifrə*
                      </label>
                      <input
                        type="password"
                        className="login-email"
                        id="oldPassword"
                        name="oldPassword"
                      />
                    </div>
                    </div>



                    <div className="col-12 col-md-6">

                      </div>


                    <div className="col-12 col-md-6">
                    <div className="position-relative">
                      <label for="newPassword" className="login-labels">
                        Yeni şifrə*
                      </label>
                      <input
                        type="password"
                        className="login-email"
                        id="newPassword"
                        name="newPassword"
                      />
                    </div>
                    </div>


                      <div className="col-12 col-md-6">

                      <div className="position-relative">
                        <label for="newConfirmPasword" className="login-labels">
                        Yeni şifrə təkrar *
                        </label>
                        <input
                          type="password"
                          className="login-email"
                          id="newConfirmPasword"
                          name="newConfirmPasword"
                        />
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end align-items-center">
                
                      <NavLink to="/profile-details"><button className="btn btn-light me-3">Şəxsi Məlumatlar</button></NavLink>
                      <button type="submit" className="primary_btn">Yadda saxla</button>
                    </div>
                  </div>
                
              </form>
            </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

export default ProfileDetails;
