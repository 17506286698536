import React, { useState, useEffect } from 'react';
import { BsFillTelephoneFill, BsFillGridFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Meta from '../components/Meta';

const Stores = () => {
  const [storesItems, setStoresItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');




  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = () => {
    storesData();
  };


  useEffect(() => {
    storesData();
  }, []);

  

  const storesData = async () => {
    try {
      const response = await fetch(
        `https://rentermmc.com:8443/api/Store/get-stores?languageCode=az&storeTitle=${searchQuery}`
      );
      const sdata = await response.json();
      setStoresItems(sdata.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  return (
    <div className='container'>
      <div className='container_n mt-3 mb-3'>
      <Meta title="Brendlər Renter MMC"/>

        <div className='col-12'>

        <div className="mb-3">


        <div className="input-group mb-3">
  <input type="text" class="form-control" placeholder="Mağaza adına görə axtarış et" aria-label="Axtar" aria-describedby="search"        value={searchQuery}
                onChange={handleSearchInputChange}/>
  <div className="input-group-append">
    <button className="input-group-text" id="basic-addon2"  onClick={handleSearchClick}>Axtar</button>
  </div>
</div>

          
          <div className='row'>
            {storesItems.map((store) => (
              <div className='col-6' key={store.storeId}>
                <div className='card my-2'>
                  <Link to={`/spage/${store.storeSlug}`}>
                  <div className='row g-0'>
                    <div className='col-md-4'>
                      <img
                        src={store.storeCover}
                        className='img-fluid rounded-start'
                        alt='...'
                      />
                    </div>
                    <div className='col-md-8'>
                      <div className='card-body'>
                        <h5 className='card-title'>{store.storeTitle}</h5>
                        <p className='card-text'>{store.storeDescription}</p>
                        <p className='card-text'>
                          <small className='text-muted'>
                            <BsFillTelephoneFill />
                             {store.storePhones }
                            <BsFillGridFill className='ms-4' />
                            {store.productCount} Elan
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Stores;
