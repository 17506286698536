import React from 'react'
import NavFooter from '../components/NavFooter'
import Meta from '../components/Meta'

const About = () => {
  return (
    <> 
    <Meta title="Haqqında Renter MMC"/>
    <NavFooter/>
    <div className='container_m'>
    <div className='container_n'>
      <div className='row'>
        <div className='col-12'>
          <div className='nav-name d-flex text-left'> Layihə haqqında</div>
       <div className='nav-description'> 
       
RENTERMMC.COM lahiyəsi Azərbaycanda yalniz icarə üçün nəzərdə tutulmuş ümumi icarə platformasidir.<br/>
<br/>
Hər bir istifadəçi RENTERMMC.COM saytindan istifadə etməklə 20 dən artiq kateqoriya üzrə məhsullari icariyə gotürə və axdara bilər.
<br/><br/>
RENTERMMC.COM-da şirkətlər və fərdi sahibkarlarla yanaşi fərdi şəxslərdə öz məhsullarini yerləşdirə bilərlər.
<br/><br/>
Hər hansi bir mövzuda irad və təklifiniz olarsa bu nömrələr ilə <b>077-613-59-59</b>; <b>070-613-59-59</b> əlaqə saxliya bilərsiniz.
<br/><br/>
<span className='nav-name fs-4'>Administrasiya</span>
<br/><br/>
Servisin inzibatçılığını Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq yaradılmış və qeydiyyatdan keçmiş “RENTER MMC” Şirkəti (VÖEN: 2007660461) həyata keçirir. Servisə dair bütün mülkiyyət hüquqları müstəsna olaraq Şirkətə aiddir.
       </div>
       </div>
       </div>
      </div>
    </div>
    </>
  )
}

export default About