const initialState = {
  user: null,
  mobileCat: false, 
  isFilterOpen: false, 
  categories: [],
  homeProducts:[],
  homeScroll: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "TOGGLE_MOBILE_CAT":
      return {
        ...state,
        mobileCat: !state.mobileCat,
      };
    case "TOGGLE_FILTER":
        return {
          ...state,
          isFilterOpen: !state.isFilterOpen,
        };
    case 'SET_CAT_DATA':
        return {
          ...state,
          categories: action.payload,
        };
    case 'SET_HOME_PRODUCTS_DATA':
          return {
            ...state,
            homeProducts: action.payload,
          };

    case 'SET_HOME_SCROLL_DATA':
            return {
              ...state,
              homeScroll: action.payload,
            };    
    default:
      return state;
  }
};

export default reducer;
