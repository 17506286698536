import React from 'react'
import NavFooter from '../components/NavFooter'
import Meta from '../components/Meta'
const Privacy = () => {
  return (
    <>
      <NavFooter />
      <div className='container_m'>
        <div className='container_n'>
          <div className='row'>
            <div className='col-12'>
            <Meta title="Məxfilik siyasəti MMC"/>

              <div className='nav-name d-flex text-left'>Məxfilik Siyasəti</div>
              <div className='nav-description'>

                <b> 1.1. </b>      Hazırkı məxfilik Siyasəti https://rentermmc.com/ Servisin İstifadəçi Razılaşmasının ayrılmaz hissəsidir.<br></br>
                <b>1.2. </b>     Hazırkı məxfilik Siyasəti İstifadəçinin şəxsi məlumatlarının alması, saxlaması, işlənməsi, istifadəsi, açılması və müdafiəsi qaydasını müəyyən edir.
                <br></br><b> 1.3. </b>     İstifadəçilərin şəxsi məlumat bazası Administrasiyanın ünvanı üzrə yerləşir.
                <br></br><b> 1.4. </b>      Fərdi məlumatlar bazasının sərəncamçısı “\RENTER” Məhdud Məsuliyyətli Cəmiyyətinin (RENTER MMC) Administrasiyasıdır.
                <br></br><b> 1.5. </b>     Elanın dərc edilməsi üçün formanı dolduran zaman və ya Servisdə  İstifadəçinin qeydiyyatı zamanı, və ya göndərmələrə abunə yazılma zamanı, həmçinin də Servisdən istifadənin başqa hallarında, İstifadəçi Administrasiyaya məlumatları və şəxsi məlumatları verir. Məlumatın toplanması həmçinin Servisin İstifadəçilərinin sorğularının keçirilməsi yolu ilə həyata keçirilə bilər.  İstifadəçilərin sorğularında iştirak İstifadəçilər icazəsi ilə həyata keçirilir.
                <br></br><b> 1.6. </b>      İstifadəçi Servisə özü haqqında şəxsi məlumatların və istənilən başqa informasiyanın verilməsi haqqında sərbəst və könüllü qərar qəbul edir, həmçinin də bununla belə şəxsi məlumatların və informasiyanın Administrasiya tərəfindən işlənməsinə, işlənməsi üçün onların Administrasiyanın tapşırığı ilə hərəkət edən başqa İstifadəçilərə və/və ya üçüncü şəxslərə ötürülməsinə öz razılığını bildirir.
                <br></br><b> 1.7. </b>      Şəxsi məlumatların işlənməsi istənilən hərəkət və ya hərəkətlərin toplanma, qeydiyyat, yığım, saxlama, uyğunlaşma, dəyişiklik, yeniləmə, istifadə və yayılma kimi məcmusudur (yayılma, reallaşdırma, ötürülmə, həmçinin xaricə) və şəxsi məlumatların, həmçinin informasiya (avtomatlaşdırılmış) sistemlərinin istifadəsi ilə məhv edilməsidir.
                <br></br><b> 1.8. </b>      Şəxsi məlumatların işlənməsinin məqsədləri, o cümlədən, aşağıdakılardır:
                <div className='p-left'><br></br><b> 1.8.1 </b>          Servisin, onun servislərinin və xidmətlərinin fəaliyyətinin yaxşılaşması üçün statistik və analitik məlumatların alınması;
                  <br></br><b> 1.8.2 </b>             göstərilən xidmətlərin spektrinin genişləndirilməsi;
                  <br></br><b> 1.8.3 </b>           Servisin və ya üçüncü şəxslərin informasiya və ya reklam xəbərlərinin alınması (yeni imkanlar, aksiyalar və başqa xəbərlər haqqında xəbərdarlıq);
                  <br></br><b> 1.8.4 </b>            İstifadəçilərin və ya üçüncü şəxslərin qanunsuz və ya icazəsiz hərəkətlərinin xəbərdarlığı və qabağının alınması;
                  <br></br><b> 1.8.5 </b>             qüvvədə olan qanunvericiliyin tələblərinə riayətin təminatı.
                  <br></br><b> 1.8.6 </b>            İstifadəçi hesablarını idarə etmək və dəstək xidməti üçün. Servis texniki problemləri həll etmək, istifadəçilərin kömək istəklərinə cavab vermək, texniki nasazlıqlar haqqında məlumatları təhlil etmək, həmçinin Servisdəki nasazlıqları aradan qaldırmaq və Servis xidmətlərini yaxşılaşdırmaq üçün istifadəçi məlumatlarından istifadə edir.
                  <br></br><b> 1.8.7 </b>             Servislə əlaqədar İstifadəçilərin maraqlarına uyğun olaraq hazırlanmış kontent və reklamları inkişaf etdirmək, göstərmək, göndərmək və izləmək üçün. Servis əlaqə məlumatlarından və İstifadəçilərin Servisdən necə istifadə etmək barədə məlumatlardan istifadə edərək İstifadəçilərin xüsusi marağına səbəb olacaq bildirişlər göndərir. Bu bildirişlər İstifadəçiləri maraqlandıra biləcək yeni xüsusiyyətlər, bülletenlər və hadisələr haqqında məlumatlar da daxil olmaqla İstifadəçilərin Servisdən aldıqları faydaları maksimum dərəcədə artırmaq məqsədi daşıyır. Servis həmçinin İstifadəçiləri yeni məhsul təklifləri, reklam aksiyaları və yarışmaları haqqında məlumatlandırır. Servis İstifadəçilərlə e-poçt, poçt, telefon və / və ya mobil qurğular vasitəsi ilə əlaqə saxlaya bilər. İstifadəçi bu bildirişləri idarə edə bilər və istədiyi vaxt razılığını ləğv etmək hüququna malikdir.
                  <br></br><b> 1.8.8 </b>             Qanunvericiliyin, tənzimləyici orqanların və hüquq-mühafizə orqanlarının tələblərinə riayət etmək və Servisin qanuni maraqlarını və hüquqlarını qorumaq üçün. Servis qanuna riayət olunmasını təmin etmək üçün həm hüquq-mühafizə orqanlarının əməkdaşları, həm də fiziki şəxslərlə işləyir. Servisin qanuni hüquq və mənafelərinin və / və ya üçüncü şəxslərin qanuni hüquq və mənafelərinin qorunması və ya qanunsuz fəaliyyətin qarşısının alınması və ya qarşısının alınması zərurəti yarandığı təqdirdə Servis, İstifadəçilər haqqında hər hansı bir məlumatı qanunun tələblərinə uyğun olaraq açıqlamalıdır.
                  Servis eyni zamanda İstifadəçi Müqaviləsini pozan fırıldaqçılıq, spam göndərmə və digər icazəsiz hərəkətlər kimi müxtəlif xidmətlərdən sui-istifadə hallarını məhdudlaşdırmaq və ya qarşısını almaq üçün məlumatlardan istifadə edir.
                  Qanunla tələb olunarsa, Servis məlumatları hüquqi və audit fəaliyyəti ilə əlaqədar olaraq, məsələn, öz biznesini əldə etmək, birləşdirmək və ya satmaq məqsədi ilə istifadə edə bilər.
                  Qanunun icazə verdiyi ölçüdə Servis, İstifadəçiləri üçüncü şəxslərə şəxsi məlumatların verilməsi barədə xəbərdar etmək üçün ağlabatan addımlar atacaq.
                  <br></br><b> 1.8.9 </b>             Veb sayt analizi. Servis, İstifadəçilərin Servislə necə qarşılıqlı əlaqədə olduğu haqqında məlumat toplamaq üçün Google Analytics kimi üçüncü tərəf xidmət təminatçıları tərəfindən təmin edilən bir neçə veb analiz vasitələrindən (və ya izləmə vasitələrindən) istifadə edir. Servis, İstifadəçi tərəfindən Servisin istifadəsini və ya İstifadəçinin məhsul, xidmət və məzmun maraqlarını araşdırır və təhlil edir. Servis, xidmətin təkmilləşdirilməsi və səhvlərin aşkarlanması və təhlili üçün bu vasitələrin verdiyi məlumatlardan istifadə edir. Bu xidmət təminatçılarının vasitələri istifadəçilərin brauzerlərində cookie-də yerləşdirir. Cookie-ləri üçüncü tərəf xidmət təminatçılarından başqa heç kim istifadə edə bilməz. Cookie-lər tərəfindən toplanan məlumatlar istifadəçinin ölkəsindən başqa bir ölkənin serverlərində ötürülə və saxlanıla bilər. Toplanan məlumatlar bu xidmət təminatçıları tərəfindən fərdi məxfilik siyasətlərinə uyğun olaraq istifadə olunur və paylaşılır. İstifadəçilər veb xidmətinin istifadəsi ilə əlaqədar olaraq cookie-lər tərəfindən yaradılan məlumatların toplanmasına və işlənməsinə razılıq verməkdə sərbəstdirlər.
                  <br></br><b> 1.8.10 </b>            Müsabiqələr və ya sorğular keçirtmək. Servis, məsələn, xidməti yaxşılaşdırmaq üçün bu üsullarla toplanan məlumatlardan istifadə edir.
                </div><br></br><b> 1.9. </b>       Servis İstifadəçilər haqqında növbəti informasiyanı toplayır:
                <div className='p-left'><br></br><b>1.9.1. </b>         Servisdən istifadə zamanı Administrasiyaya İstifadəçinin daxil etdiyi şəxsi informasiyanı göstərir və ya başqa üsulla açır. Belə informasiya, xüsusi halda (lakin, məhdudlaşdırılmadan), İstifadəçinin adı və soyadı, elektron poçt ünvanı və parolu, telefon nömrəsi, İstifadəçinin yerləşdiyi yer, həmçinin ünvanı ola bilər.  Əgər İstifadəçi sosial şəbəkənin profili vasitəsilə daxil olursa, Servis həmçinin belə sosial şəbəkənin profilində yerləşdirilmiş informasiyanı toplaya bilər. İstifadəçi ona verilmiş informasiyaya görə məsuliyyət daşıyır, halbuki Servis İnternetdə İstifadəçinin məlumatlarının yayılması və dərc edilməsi üçün kanalı verir.
                  <br></br><b> 1.9.2 </b>          Servisin, ona giriş zamanı, proqram təminatı tərəfindən avtomatik toplanan texniki informasiya.
                  <br></br><b> 1.9.3 </b>         İstifadəçi başqa bir veb servis profilindən istifadə edərək daxil olduqda (məsələn, Facebook, Google+, vkontakte, odnoklassniki.ru və s.), Servis həmçinin belə bir veb servisin müvafiq profilində yerləşən, İstifadəçinin təqdim edəcəyinə dair razılıq verdiyi adı da daxil olmaqla, e-poçt və profillə əlaqəli məlumatlarını toplaya bilər. İstifadəçi göstərilən məlumatların Servisə  köçürülməsinə razılıq verir. Bu icazə istənilən vaxt Servisə  sorğu göndərməklə ləğv edilə bilər.
                  <br></br><b> 1.9.4 </b>          Servis İstifadəçinin cihazının fiziki yeri haqqında məlumat toplaya bilər. Fərdi İstifadəçinin yeri İstifadəçinin cihazından alınan IP ünvanı və ya coğrafi məkan məlumatları istifadə edərək təyin edilə bilər. Servis, məsələn, daxili analiz və performans monitorinqi üçün və ya məzmunu göstərmək və ya İstifadəçinin yeri ilə əlaqədar təkliflər vermək üçün funksiyaları təmin etmək, habelə xidmətləri təkmilləşdirmək və fərdiləşdirmək üçün istifadəçinin yerləşdiyi yer haqqında məlumatı istifadə edə və saxlaya bilər.
                  <br></br><b> 1.9.5 </b>         İstifadəçi tərəfindən təqdim olunan məlumatlar Servis və ya digər üçünçü şəxslərin Servis sorğusuna əsasən keçirtdiyi konkurs və sorğularda iştirakı ilə əlaqadər  təqdim olunur.
                  <br></br><b> 1.9.6 </b>         Servis, istifadəçilər və onların hərəkətləri haqqında məlumatı marketoloqlardan, tərəfdaşlardan, tədqiqatçılardan və digər mənbələrdən (ictimai məlumat bazaları daxil olmaqla) alır. Servis İstifadəçidən alınan məlumatları digər İstifadəçilərdən və üçüncü şəxslərdən alınan məlumatlarla birləşdirə bilər.
                </div><br></br><b>1.10. </b>    Xidmət, eləcə də Administrasiya irqi və ya etnik mənşə, milli mənsubiyyət, siyasi, dini və dünyagörüşü məsləki, siyasi partiyalara və həmkarlar ittifaqlarına üzvlük, cinayət işi üzrə məhkumluq barədə məlumatları, habelə sağlamlıq, cinsi meyllilik, cinsi həyatla bağlı, biometrik və ya genetik məlumatları toplamır və emal etmir.
                <br></br><b>1.11. </b>     İstifadəçinin buna icazə verdiyi hallar, habelə hazırkı Razılaşma və ya qanunvericilikdə nəzərdə tutulmuş hallar istisna olmaqla, Servis və Administrasiya İstifadəçilər tərəfindən təqdim edilmiş hər hansı bir şəxsi və ya digər məlumatı Servisə aidiyyəti olmayan üçüncü şəxslərə ötürmür.
                <br></br><b>1.12. </b>      Hansı reklam və ya xidmətlərin İstifadəçiləri maraqlandıra biləcəyini daha yaxşı anlamaq məqsədilə, hazırkı Razılaşmanın icrası (İstifadəçilərə xidmətlərin göstərilməsi), Servisin ümumi keyfiyyətinin və səmərəliliyinin yaxşılaşdırılması məqsədi ilə və ya Servisin elmi tədqiqatlara töhfə verməsini təmin etmək məqsədilə ki, bu da Administrasiyanın fikrincə böyük sosial faydaya səbəb olacaqdır, Administrasiya müəyyən şəxssizləşdirilmiş məlumatları (İstifadəçiləri ayrı-ayrılıqda müəyyən etməyə imkan verməyən məlumatları) kənar xidmət tədarükçülərinə, etibarlı tərəfdaşlara və ya səlahiyyətli araşdırıcılara ötürə bilər. Bu zaman ötürülən məlumatlar hazırkı Məxfilik Siyasətinin predmeti hesab edilir, cəlb edilmiş üçüncü şəxslər isə əldə etdikləri məlumatları Administrasiyanın xidmətlərinin göstərilməsindən başqa digər hallarda istifadə etmək hüququna malik deyillər.
                Servis İstifadəçi haqqında məlumatları Servis üçün xidmət göstərən üçüncü tərəf xidmət təminatçılarına ötürə və açıqlaya bilər, məhz: veb saytların yerləşdirilməsi, məlumatların təhlili, informasiya texnologiyaları və əlaqədar infrastrukturun təmin edilməsi, e-poçt və mesajların çatdırılması (bildirişlər), audit, xidmət, dəstək məlumatları və CRM sistemlərini idarə etmək üçün xüsusi reklamların və xidmət təminatçılarının təmin edilməsində Servisə kömək edən xidmətlər. Bu xidmət təminatçıları Servisə xidmətlərin çatdırılmasına kömək edirlər (məsələn, Servisə fərdiləşdirilmiş reklamların çatdırılmasına kömək edə bilər və ya Servisə potensial qanunsuz fəaliyyətlərin qarşısını almağa, aşkar etməyə, azaltmağa və araşdırmağa kömək edə bilərlər). Bu xidmət təminatçıları yalnız öz funksiyalarını yerinə yetirmək üçün lazım olan məlumatları əldə edə biləcəklər. İstifadəçinin məlumatlarını başqa məqsədlər üçün açıqlamamaq və istifadə etməmək məcburiyyətindədirlər. Bəzi hallarda, bu xidmət təminatçılarından Servis üçün ciddi şəkildə xidmət göstərmələri tələb olunmur, lakin onlar Servisin daha yaxşı olmasına kömək edirlər.
                Məsələn, Servis, Servisin istifadəsini təhlil  və monitorinq etmək üçün Google Analytics-dən istifadə edir. Google Analytics, Google tərəfindən veb trafikini izləyən və hesabat verən bir veb analitik xidmətidir. Google toplanmış məlumatlardan, Servis istifadəsini izləmək və monitorinq üçün istifadə edir. Bu məlumatlar digər Google servislərinə ötürülür. Google, toplanmış məlumatlardan öz reklam şəbəkəsi üçün reklamları kontekstləşdirmək və fərdiləşdirmək üçün istifadə edə bilər. İstifadəçi, Servisdəki Google Analytics-dən çıxmaq üçün brauzer parametrlərini ayarlayaraq Servisdəki fəaliyyətlərində Google Analytics-dən imtina edə bilər.  Parametrlərdəki ayarlama Google Analytics-ə aktiv daxil olma haqqında məlumatın Google Analytics JavaScript-ə (ga.js, analytics.js və dc.js)  ötürülməsinin qarşısını alır.
                Servis, Google AdSense və DoubleClick for Publishers reklamlarını göstərmək üçün onlayn reklam xidmətlərindən də istifadə edir.
                Sifarişçi öz şəxsi məlumatlarının təqdim edilməsini, toplanılan məlumatların siyahısını, onların toplanması və istifadəsi üçün əsaslar və məqsədlərlə tanış olmasını, fərdi məlumatların üçüncü tərəfə ötürülməsi mümkünlüyünü, habelə fərdi məlumatlarının digər mümkün istifadəsi barədə məlumatlandırıldığını təsdiq edir və öz fərdi məlumatlarının toplanmasına, emalına və saxlanmasına, şəxsi məlumatlarının istifadəsinə razılıq verir.
                <br></br><b>1.13. </b>      Administrasiya Servisdən istifadə prosesində İstifadəçilər tərəfindən verilmiş informasiyanı və şəxsi məlumatları satmır və icarəyə vermir.
                <br></br><b>1.14. </b>      İstifadəçilər arasında qarşılıqlı təsiri yüngülləşdirmək üçün, xidmətlər başqa İstifadəçilərin əlaqə informasiyasına məhdudlaşdırılmış girişi nəzərdə tutur. Başqa İstifadəçilər tərəfindən verilmiş məlumatlardan istifadə hüququ İstifadəçi razılaşması ilə məhdudlaşdırılır.
                <br></br><b>1.15. </b>      Servisin İstifadəçisi başqa İstifadəçi tərəfindən verilmiş məlumatlardan, belə İstifadəçinin yazılı icazəsi olmadan və ya belə məlumatlardan hər hansı başqa yolla istifadə etmək hüququnun təsdiqi olmadan istifadə etməməyi öhdəsinə götürür. Servisdən istifadə ilə əlaqədar başqa İstifadəçilər haqqında İstifadəçi tərəfindən alınmış bütün məlumatlar yalnız Mallar barəsində sazişlərin icrasında istifadə edilə bilər. Beləliklə, İstifadəçiyə düz reklam göndərməsi və ya arzu edilməyən elektron xəbərlərin başqa göndərməsi məqsədləri üçün elektron ünvandan istifadə etməyə, başqa İstifadəçinin telefon nömrəsi kimi, həmçinin xəbərsiz edilən qeyri-qanuni hərəkətlərə və ya başqa İstifadəçinin xəbəri və razılığı olmadan həyata keçirilmiş hərəkətlərə icazə verilmir.
                <br></br><b>1.16. </b>     Servisin İstifadəçisi onun Servisdən istifadə prosesində verdiyi  şəxsi və başqa məlumatları istənilən vaxt dəyişdirmək, silmək və ya başqa üsulla redaktə etmək imkanına malikdir.
                <br></br><b>1.17. </b>      İstifadəçi onun göstərdiyi istənilən informasiyanın və məlumatların dəqiqliyi və düzgünlüyü üçün məsuliyyət daşıyır.
                <br></br><b>1.18. </b>      Əgər İstifadəçi girişi, qeydiyyatı həyata keçirmişsə və OpenID xidmətinin köməyi ilə Servisdən istifadə edirsə, İstifadəçi onun məlumatlarının  ötürülməsi və onlardan istifadə qaydasını belə xidmətin parametrlərində qura bilər.
                <br></br><b>1.19. </b>      Servis və Administrasiya İstifadəçinin şəxsi məlumatlarının üçüncü şəxslərin icazəsiz girişindən müdafiəsi üçün bütün səmərəli ölçüləri qəbul edir.
                <br></br><b>1.20. </b>  .     Servis tərəfindən toplanmış və işlənmiş bütün şəxsi məlumatlar bir və ya bir neçə qorunan korporativ şəbəkədən kənarda giriş olmayan serverdə saxlanılır. Administrasiyanın İstifadəçilərin şəxsi və başqa informasiyasından girişlə və istifadə ilə funksiyaları yerinə yetirən bütün əməkdaşları üçüncü şəxslərə İstifadəçilər haqqında informasiyanın yayılmaması haqqında razılaşma imzalamışlar.

                <br></br><br></br><b className='fs-5'>2. Cookies, veb-mayaklar, və oxşar texnologiyalar</b><br></br>

                <br></br><b>2.1. </b>      Servis informasiyanın saxlanılması üçün cookies fayllarından, veb-mayaklardan və başqa oxşar texnologiyalardan istifadə edə bilər. Bu fayllar veb-saytın və onun əlavələrinin istifadəsini yüngülləşdirmək məqsədi ilə, Servis xidmətlərinin keyfiyyətinin artırılması məqsədi ilə (həmçinin təhlükəsizlik), həmçinin reklam məqsədi ilə istifadə olunur.
                <br></br><b>2.2. </b>        Servis tərəfindən İstifadəçidən alınan istənilən informasiya, həmçinin cookies fayllarının İstifadəçinin brauzerində yerləşdirilməsi, xəbərdarlıq vasitəsilə və İstifadəçinin icazəsi ilə həyata keçirilir. Servisdən istifadəni davam edərək, İstifadəçi Servisə cookies fayllarının İstifadəçinin brauzerində saxlamasına öz icazəsini verir.
                <br></br><b>2.3. </b>   Servis cookies-dən və oxşar texnologiyalardan, İstifadəçinin brauzerinin fəallığı prosesində İstifadəçinin qurğusunda qalan və cookies, həmçinin də daha uzun müddətli dövr ərzində İstifadəçinin qurğusunda qalan başqa oxşar texnologiyalardan istifadə edir. İstifadəçi belə cookies və oxşar texnologiyaları bloklamaqda, silməkdə və ya kəsməkdə, əgər İstifadəçinin qurğusu buna icazə verirsə, haqlıdır.
                <div className='p-left'><br></br><b>2.3.1. </b>   Cookies – kompüter terminologiyasında Servisdən alınmış mətn və ya ikili məlumatlar şəklində informasiyanın təsviri üçün istifadə edilən anlayışdır ki, İstifadəçidə saxlanılır, yəni brauzerdə, sonra isə, əgər Servisin İstifadəçisi ona təkrar olaraq baş çəkirsə, Servisə yollanır. Beləliklə, Servis baş çəkmə vaxtı İstifadəçinin brauzerini qeyd edir. Cookies faylları sistemə giriş və statistika yığımı üçün lazımı məlumatları yazaraq Servisdən istifadəni yüngülləşdirir. Cookies falları özündə şəxsi məlumatları saxlamır.
                  <br></br><b>2.3.2. </b> Veb-mayaklar – Servisdə, həmçinin onun xidmətlərində, əlavələrdə, mübadilə xəbərlərində, və İstifadəçini müəyyən etmək üçün adətən cookies ilə uyğunluqda işləyən alətlərdə qoşula bilən kiçik qrafik təsvirlərdir (həmçinin "nöqtə markörləri" və ya "Şəbəkə mayakları" kimi məlumdur).
                  <br></br><b>2.3.3. </b> Oxşar texnologiyalar – lokal ümumi obyektləri və ya lokal anbarı və proqram veb-əlavələrinin başqa metodları istifadə edən, brauzerdə və ya qurğuda informasiyanı saxlayan, "flashcookies" "HTML 5 cookies" kimi, texnologiyalardır.  Bu texnologiyalar İstifadəçinin bütün brauzerlərində işləyə bilər, bəzi hallarda isə tamamilə brauzerlə idarə oluna bilmirlər və İstifadəçi tərəfindən təyin edilmiş əlavələr və ya qurğular vasitəsilə bilavasitə idarəetməni tələb edə bilərlər.
                </div><br></br><b>2.4. </b>     Administrasiya cookies və bu kimi texnologiyalara icazəsiz girişin qarşısını almaq üçün təhlükəsizlik tədbirləri görür. Analoji təhlükəsizlik tədbirlərini görməyi İstifadəçi öz öhdəsinə götürür. Administrasiya zəmanət verir ki, yalnız Administrasiyanın və/və ya Servis xidmətlərinin səlahiyyətli tədarükçülərinin cookies məlumatlarına girişi vardır.
                <br></br><b>2.5. </b>     Servisin xidmətlərinin təchizatçıları Servisin müxtəlif aspektləri ilə kömək edən şirkətlərdir. Administrasiya Servisin uyğun olan xidmətlərinin İstifadəçiyə verilməsi, həmçinin də Servisin bilavasitə fəaliyyəti ilə bağlı başqa məqsədlər üçün xidmətlərin bəzi səlahiyyətli təchizatçılarından istifadə edir. Xidmətlərin belə təchizatçıları həmçinin Servisin (kənar cookies) xidmətləri vasitəsilə cookies-i İstifadəçinin qurğusunda yerləşdirə bilərlər. Onlar həmçinin başqa informasiyanı, məsələn, IP-ünvan və ya başqa eyniləşdiriciləri, toplaya bilərlər.
                <br></br><b>2.6. </b>    Servisin yuxarıda göstərilmiş texnologiyalardan istifadə vasitəsilə toplayıb və saxladığı istənilən informasiyadan İstifadəçinin icazəsi ilə istifadə olunur.
                <br></br><b>2.7. </b>     İstifadəçi brauzerin və ya qurğunun parametrlərində cookies-i idarə edə bilər. İnformasiyanın toplanmasından və saxlanılmasından imtina üçün brauzerin parametrlərində "Cookies-i saxlamamaq" bəndində bayraqcığı qurmaq, həmçinin "Cookies-i təmizləmək" düyməsini basmaq lazımdır.
                <br></br><b>2.8. </b>      Sistemdə olan texniki xarakterli informasiya, məsələn, ip-ünvanlar, Servis tərəfindən şəbəkə avadanlığının xidməti üçün, həmçinin statistik və başqa informasiyanın ümumiləşdirilməsi üçün istifadə olunur.
                <br></br><b>2.9. </b>      İstifadəçinin fərdi ehtiyaclarına və maraqlarına uyğunlaşdırılan verilən servislərin yüksək keyfiyyətli təminatı məqsədi ilə Servis İstifadəçinin sistemə son girişinin məlumatlarını saxlayır.

                <br></br><br></br><b className='fs-5'>3. Məxfiliyin siyasətinə dəyişikliklərin daxil etməsi</b><br></br>

                <br></br><b>3.1. </b>     Administrasiya Məxfilik Siyasətinə dəyişikliklər edə, silə və ya qaydalarını yeniləyə bilər.
                <br></br><b>3.2. </b>      Əgər İstifadəçi daxil edilmiş dəyişikliklərlə razı deyilsə, o Servisdən istifadəni dayandırmalıdır. Əgər İstifadəçi Servisdən istifadə etməyə davam edirsə, o razılaşır və bütün dəyişiklikləri və Məxfilik siyasətinin yeni redaksiyasını bütövlükdə qəbul edir.

              </div></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy