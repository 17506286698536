import React from 'react'
import NavFooter from '../components/NavFooter'
import Meta from '../components/Meta'

const Rules = () => {
  return (
    <>
    <NavFooter/>
    <Meta title="Qaydalar Renter MMC"/>

    <div className='container_m'>
    <div className='container_n'>

      <div className='row'>
        <div className='col-12'>
          <div className='nav-name d-flex text-left'>RENTER MMC QAYDALAR</div>
       <div className='nav-description'>
       <span className='nav-name fs-5'>Elanin yerlesidirlmesi</span><br></br><br></br>
 
 Bir ay(30gün) ərzində isdifadəçi saytin qaydalarina zidd olmayan katiqoriya və subkatiqoriyaya aid ödənişiz şəkildə yalniz bir əded elan paylaşa bilər.<br></br>
 Əger elanin müddəti bitibsə isdifadəçi elani bərpa edə bilər şəxsi kabinetinə daxil olaraq və ya yeni elanla əvəz edə billər.<br></br>
 Elan yerləşdirildikdə elan saytin qaydalarina tam olaraq uyuğun olmalidi. <br></br>
 Elan yerləşdirdikdən sonra adminstrasiya tərefindən yoxlanilir və qayda pozuntusu aşkar edilməse təsdiqlənir.<br></br>
 Aliş və satiş tipli elanlarin sayta yerləşdirilməsi qadağandir.<br></br>
 Isdifadəçi sayta yerləşdiridiyi hər bir elana görə şəxsən məsuliyyət daşiyir.<br></br>
 QAYDALARIN POZULMASI HALINDA HƏDDA ÖDƏNIŞIN HƏYATA KEÇIRILMIŞ ELANLARDA ADMINSTRASIYA TƏRƏFINDƏN SILINƏ BILƏR.<br></br><br></br>
 <span className='nav-name fs-5'>TƏSVIR</span>
 <br></br><br></br>
 Elanin təsviri tam olaraq elanin şəklinə və təklif olunan məhsulun təsvirinə tam uyğun olmalidir.<br></br>
 Bir elanda yalniz bir məhsul qeyd olunmalidir.<br></br>
 Əgər isdifadəci bir necə məhsul təklif edirsə hərbir məhsul ücün yeni elan yaradilmalidir.<br></br>
 Elanin təsvirində məshul haqqinda dəqiq və ətrafli məlumat qeyd olunmalidir.<br></br>
 Elanin təsvirində link əlavə etmək qadağandir.<br></br><br></br>
  
 <span className='nav-name fs-5'>QIYMƏT</span><br></br><br></br>
 Elani yerləşdirdikdə məhsulun qiymətini müvaffiq xanada qeyd edin.<br></br>
 Məhsulun qiymətini şərti olaraq görsətmək qadağandi əks halda elaniniz adminstrasiya tərəfindən silinə bilər.<br></br>
  
  
 <br></br><br></br>
  
 <span className='nav-name fs-5'>ŞƏKILLƏR</span><br></br><br></br>
 Şəkillər keyfiyyətli olmalidir.<br></br>
 Şəkillərin maksimal sayi 20-dir, minimum 1 şəkil olmalidir.<br></br>
 Şəkil elanina məzmununa uyğun olamlidir.<br></br>
 Şəkildə yalniz təklif olunnan məhsul gösdərilməlidir.<br></br>
 Qaydalara uyğun olmayan şəkillerin yerləşdirilməsi qadağandir.<br></br>
 <br></br>
 <span className='nav-name fs-5'>ƏLAQƏ</span><br></br><br></br>
  
 Əlaqə vasitəlerinizi yalniz müvafiq xanada qeyd edin.<br></br>
 Əlaqə vasitələrizin doğruluğuna diqqət yetirin(mobil nömrə,ad,elektron poct,ünvan)<br></br>
 Əlaqə vasitələrinizin işlək vəziyətdə olmasina diqqət yetirin,əks halda Pin kodu əlde etmək mümkün olmayacaq.<br></br>
  
 <br></br>
  
 <span className='nav-name fs-5'>YALNIZ KATIQORIYADA YERLƏŞDİRİLƏN ELANLAR</span><br></br><br></br>
  
 Elanin təyinatina,başliğina,adina uyğun olamayan katiqoriyalarda yerləşdirmək və isdifadəçilərdə anlaşilmazliq yarada biləcək məlumatlarin yerləşdirilməsi qadağandir.
 Elandaki bütün məlumatlar həqiqəti əks etdirməlidir.<br></br>
 Elaninizin məzmununa ən uyğun katiqoriya seçin.<br></br>
 Elaninizin düzgün katiqoriyada olduğuna diqqət yetirin əks halda elaniniz silinə ve ya uyğun katiqoriyaya köçürülməsinə səbəb ola bilər.
 <br></br><br></br>
 BAŞLIQ
 <br></br><br></br>
 Elanin başliği qisa və məhsula uyğun olmalidir.<br></br>
 Başliq müştərinin diqqətini cəlb edən baslica amildi.<br></br>
 Başliq qrammatik cəhetdən düzgün yazilmalidir.<br></br>
 Başliqda yol verilmir "əlaqə məlumatlari"    "qeyri-hərif simvollar"   "@ l = +"<br></br>
 <br></br>
 <span className='nav-name fs-5'>MAĞAZA VƏ ŞIRKƏTLƏR</span><br></br><br></br>
  
 Yuxarida qeyd olunan qaydalarin hər biri mağazalar və şirkətlər ücundə kecərlidir.<br></br>
 Mağaza və şirkətlər adinnan elan yerləşdirmək üçün ödenişli paketlərdən biri əldə olunmalidir.<br></br>
 Paketin aktivlik müddəti 30-gündür.<br></br>
 Mağaza və şirkətlər yalniz qeydiyyatdan kecdikləri katiqoriyaya uyğun elan yerləşdirə bilərlər.<br></br>
 Qaydalara riyayət etməyən istifadəçilərin elanlari ödeniş olunmasina baxmayaraq saytdan silinəcək. Bu halda ödənilen pul geri qaytarilmir.<br></br>
  <br></br>
 <b>RENTER MMC saytinin rəhbərliyi tərəfindən xəbərdarliq etmədən,birtərəfli qaydada istənilən vaxt qaydalari dəyişə bilər.</b><br></br>
        
        
 </div>
 </div>
         </div>
      </div>
    </div>
    </>
  )
}

export default Rules