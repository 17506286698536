import React, { useState } from 'react';
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { ReactComponent as Animationleft } from '../Images/animation-left.svg';
import { ReactComponent as Animationright } from '../Images/animation-right.svg';
import { ReactComponent as Logo } from '../Images/logo.svg';
import { ReactComponent as RenterMMc } from '../Images/rentermmc.svg';
import { ReactComponent as EllipseLeft } from '../Images/Ellipse-left.svg';
import { ReactComponent as EllipseRight } from '../Images/Ellipse-right.svg';
import { ReactComponent as Avatar } from '../Images/avatar.svg';
import { ReactComponent as Mail } from '../Images/mail.svg';
import { ReactComponent as Call } from '../Images/phone.svg';
import { ReactComponent as OpenEye } from '../Images/openeye.svg';
import { ReactComponent as CloseEye } from '../Images/closeeye.svg';
import bck_log_1 from "../Images/bckimages.png"
import bck_log_2 from "../Images/bckimage2.png"
import bck_log_3 from "../Images/bckimage3.png"
import { Link } from 'react-router-dom';
import Meta from '../components/Meta';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);


  const [formData, setFormData] = useState({
    userName: '',
    userFirstName: '',
    userLastName: '',
    userPhone: '',
    userEmail: '',
    userPassword: '',
    confirmPassword: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        'https://rentermmc.com:8443/api/auth/user-register',
        formData
      );
      const data = response.data;
      if (data.statusCode === 201) {
        if (data.isSuccessful === true) {
          window.location.href = "/actioninfo/regsuccess";
        } else {
          toast.error(data.messages[0]);
        }
      } else {
        toast.error(data.messages[0]);
      }
    }  catch (error) {
      console.error('Error submitting form:', error);
      if (error.response && error.response.data && error.response.data.messages) {
        toast.error(error.response.data.messages[0]);
      } else {
        toast.error("Gözlənilməyən xəta baş verdi.");
      }
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRePasswordVisibility = () => {
    setShowRePassword(!showRePassword);
  };


  return (
    <div className='login_register_walper'>
        <ToastContainer />
      <div className='login-page d-flex'>

      <Meta title="Qeydiyyat Renter MMC"/>

        <div className='col-md-6 only_desk login-left-side'>
          <div className='d-flex align-items-center p-3'>
            <Link to="/"><Logo /></Link>
            <div className="line"></div>
            <Link to="/"><RenterMMc /></Link>
          </div>
          <div className='row h-25' >
            <div className='col-6 p-5 position-relative'>
              <EllipseLeft className='position-absolute' height={200}/>
              <img src={bck_log_1} alt='' height={200} className='position-absolute' />
            </div>
            <div className='log-text col-6 d-flex flex-column justify-content-center'>
              <span>Hər növ maşınların</span>
              <span className='text-white'>Kirayəsi</span>
            </div>

          </div>
          <div className='row h-25' >

            <div className='log-text col-6 d-flex flex-column justify-content-center'>
              <span>Hər növ evlərin və obyektlərin</span>
              <span className='text-white'>Kirayəsi</span>
            </div>
            <div className='col-6 p-5 position-relative'>
              <EllipseRight className='position-absolute ellipse-right' height={200} />
              <img src={bck_log_2} alt='' height={200} className='position-absolute' />
            </div>
          </div>
          <div className='row h-25' >
            <div className='col-6 p-5 position-relative'>
              <EllipseLeft className='position-absolute' height={200} />
              <img src={bck_log_3} alt='' height={200} className='position-absolute' />
            </div>
            <div className='log-text col-6 d-flex flex-column justify-content-center'>
              <span>Hər növ geyimlərin
              </span>
              <span className='text-white'>Kirayəsi</span>
            </div>

          </div>
        </div>
        <div className='col-12 col-md-6  login-right-side'>
          <div className='register-rightside-animations'>
            <Animationleft />
            <Animationright />
          </div>
          <div className='register-page-brand d-flex justify-content-center'>
            RENTER<div className='text-black'>MMC</div>
          </div>
          <div className='login-details'>

            <div className='login-password register-details w-75'>
          

              <form onSubmit={handleSubmit} className='col-12'>

                <div className='position-relative'>
                  <label for="userFirstName" className='login-labels'>Ad*</label>
                  <br></br>
                  <input type='text' className='login-email' name='userFirstName' required id='userFirstName' onChange={handleInputChange} />
                  <Avatar className='position-absolute  icon-place' />
                </div>

                <div className='position-relative'>
                  <label for="userLastName" className='login-labels'>Soyad*</label>
                  <br></br>
                  <input type='text' className='login-email' name='userLastName' required id='userLastName' onChange={handleInputChange} />
                  <Avatar className='position-absolute  icon-place' />
                </div>

                <div className='position-relative'>
                  <label for="userName" className='login-labels'>İstifadəçi adı*</label>
                  <br></br>
                  <input type='text' className='login-email' name='userName' required id='userName' onChange={handleInputChange} />
                  <Avatar className='position-absolute  icon-place' />
                </div>
                <div className='position-relative'>
                  <label for="userEmail" className='login-labels'>Elektron-poçt*</label>
                  <br></br>
                  <input type='email' className='login-email' name='userEmail' required id='userEmail' onChange={handleInputChange} />
                  <Mail className='position-absolute  icon-place' />
                </div>
                <div className='position-relative'>
                  <label for="userPhone" className='login-labels'>Əlaqə nömrəsi *</label>
                  <br></br>
                  <input type='tel' className='login-email' id='userPhone'required name='userPhone' onChange={handleInputChange} />
                  <Call className='position-absolute  icon-place' />
                </div>
                <div className='position-relative'>
                  <label for="userPassword" className='login-labels'>Şifrə*</label>
                  <br></br>
                  <input type={showPassword ? "text" : "password"}
                    className='login-email' id='userPassword' name='userPassword' onChange={handleInputChange} />
                  {showPassword ? <FaEyeSlash className="fs-4 input_icon position-absolute icon-place mt-1"
                    onClick={togglePasswordVisibility} /> : <FaEye className="fs-4 input_icon position-absolute icon-place mt-1"
                      onClick={togglePasswordVisibility} />}
                </div>
                <div className='position-relative'>
                  <label for="confirmPassword" className='login-labels'>Şifrənin təkrarı*</label>
                  <br></br>
                  <input type={showRePassword ? "text" : "password"} required
                    className='login-password' id='confirmPassword' name='confirmPassword' onChange={handleInputChange} />
                                    {showRePassword ? <FaEyeSlash className="fs-4 input_icon position-absolute icon-place mt-1"
                    onClick={toggleRePasswordVisibility} /> : <FaEye className="fs-4 input_icon position-absolute icon-place mt-1"
                      onClick={toggleRePasswordVisibility} />}
                  </div>

                <div className='d-flex justify-content-center align-items-center  apply-info'>

                  <input type='radio'></input><div><span className='text-secondary'>Razıyam</span><Link to='/term-condition'>Terms of Use</Link>  <span className='text-secondary'>&</span><Link to='/privacy'> Privacy Policy</Link></div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button className='register-submit' type='submit'>Qeydiyyat</button>
                </div>
                <div className='d-flex justify-content-center align-items-center  apply-info  mb-3'>
                  <div className='mt-3'><span className='text-secondary'>Artıq hesabınız mövcuddur?</span><Link to='/login'> Daxil ol</Link></div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Register