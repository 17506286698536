import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ProductCard from '../components/ProductCard';
import BrandsLogo from "../Images/B-logo.png";
import InfiniteScroll from "react-infinite-scroll-component";
import Carousel from 'react-elastic-carousel';
import Meta from '../components/Meta';
import { FaThLarge ,FaSearch} from "react-icons/fa";
import { Link } from 'react-router-dom';

const Home = ({ mobileCat,toggleMobileCat,categories,setCategoryData,homeProducts,setHomeProductsData }) => {

  const [loaderEnd, setLoaderEnd] = useState(false);
  const [exScroll, setExScroll] = useState(sessionStorage.getItem('homeScroll'));
  const [products, setProducts] = useState([]);
  const [promotionsProducts, setPromotionsProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [borderColor, setBorderColor] = useState("1px solid #E6E6E6");
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [homeScroll, setHomeScroll] = useState(sessionStorage.getItem('homeScroll'));


  useEffect(() => {
    window.scrollTo(0, exScroll);
  }, [loaderEnd]);


  useEffect(() => {
    setLoaderEnd(true);
    promotionsData();
  }, []);

  useEffect(() => {
    sessionStorage.setItem('homeScroll', homeScroll);
  }, [homeScroll]);


  useEffect(() => {

    const handleScroll = () => {
      setHomeScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [homeScroll]);




  useEffect(() => {
    homeProductDataFetch();
  }, [page]);









  
  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    if (storedPage !== null) {
      setPage(parseInt(storedPage));
    }
    
    const sessionProducts = JSON.parse(sessionStorage.getItem('products')) || [];
    if (sessionProducts.length > 0) {
      setProducts(sessionProducts);
      
    }

  }, []); 


  const handleNextPage = () => {
    if (hasMorePages) {
      setPage(page + 1);
      sessionStorage.setItem('currentPage', parseInt(page+1));
    }
  };


  const homeProductDataFetch = async () => {
    setIsLoading(true);
  
    try {
      const response = await fetch(
        `https://rentermmc.com:8443/api/Product/get-all-products?LanguageCode=az&pageIndex=${page}`
      );
  
      const data = await response.json();
      if (data.data.items.length > 0) {

        setProducts((prevProducts) => {

          const newProducts = [...prevProducts, ...data.data.items];
          // Filter out duplicate products based on a unique identifier (e.g., productId)
          const uniqueProducts = Array.from(new Set(newProducts.map(product => product.productId)))
            .map(productId => newProducts.find(product => product.productId === productId));
          sessionStorage.setItem('products', JSON.stringify(uniqueProducts));
          return uniqueProducts;
        });
  
        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }
    } catch (error) {
      setHasMorePages(false);
      console.error('Error fetching data:', error);
    }
  };
  

  
  const promotionsData = async () => {
    try {
      const response = await fetch(
        `https://rentermmc.com:8443/api/Product/get-home-promotions?LanguageCode=az&PromotionType=vip`
      );
      const pdata = await response.json();
      setPromotionsProducts(pdata.data);
      setBorderColor("3px solid #8A53FE")  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
<div className='container_m'>
<div className='container_n'>
<Meta title="Əlaqə Renter MMC"/>


         <div className='only_desk'>
   <header className='header-categories my-3'>

        <div className='row'>
            <div className='col-12'>




<Carousel itemsToShow={7} pagination={false}>
  <div className='text-center w-100'>
    <Link to={`/stores`} className='color_b pt-2'>
   <div className='carousel-image bg-black'><img src={BrandsLogo} alt='logo'/></div>
   <div className='d-flex align-items-center justify-content-center mt-2'>Brendlər</div>
   </Link>
  </div>

  {categories.map((cat) => (

  <div className='text-center w-100'>
    <Link to={`/search/?CategoryId=${cat.categoryId}`} className='pt-2'>
  <div className='w-100 d-flex justify-content-center'><img src={cat.categoryImage} className='carousel-image' alt={cat.categoryTitle} height={60}/></div>
  <div className='carousel-titles text-center mt-2 color_b  w-100'>
  {cat.categoryTitle.length <= 12 ? cat.categoryTitle : `${cat.categoryTitle.slice(0, 12)}...`}

  </div>
  </Link>
  </div>
                      ))}



</Carousel>
            </div>
        </div>


   </header>
   </div>
   <div className='only_mobile'>
    <form method='GET' action={`/search`} >
   <div className="input-group mt-3 mb-3 ps-3 pe-3">
  <input type="text" className="form-control mobile_searc_input" name="keyword" placeholder="Axtarışınız üzrə məlumatları daxil edin" required/>
  <button type='submit' className="input-group-text mobile_searc_btn" id="basic-addon2"><FaSearch/></button>
</div>
</form>

<div className="col-12 mt-3 mb-3 ps-3 pe-3">
      <Carousel itemsToShow={6} showArrows={false}  pagination={false}>
        <div className="text-center w-100">
          <div className="m_page_short_cats" onClick={toggleMobileCat}>
            <div className="content">
              <FaThLarge className="h_1_5_rem primary_color" />
            </div>
          </div>
          <span className="min_font">Kateqoriyalar</span>
        </div>

        <div className="text-center w-100">
          <Link to={`/stores`} className="w-100 color_b">
            <div className="m_page_short_cats bg_black">
              <div className="content">
                <img src={BrandsLogo} alt="logo" className="h_1_5_rem" />
              </div>
            </div>
            <span className="min_font">Brendlər</span>
          </Link>
        </div>

        {categories.map((cat) => (
          <div className="text-center w-100" key={cat.categoryId}>
            <Link to={`/search/?CategoryId=${cat.categoryId}`} className="w-100">
              <div className="m_page_short_cats">
                <div className="content">
                  <img src={cat.categoryImage} alt="logo" className="w-100" />
                </div>
              </div>
              <span className="min_font color_b">{cat.categoryTitle}</span>
            </Link>
          </div>
        ))}
      </Carousel>
    </div>

   </div>

  
   <div className='vip-header d-flex align-items-center'>
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-12 d-flex'>
          <div className='col-6 vip-left'>VIP ELANLAR</div>
          <div className='col-6 vip-right d-flex justify-content-end'>
          <Link to={`/vipproducts`}>Bütün VIP elanlar</Link></div>
        </div>
      </div>
    </div>
   </div>
   <div className='container-xxl'>
    <div className='row'>
      <div className='col-12'>
      <ProductCard products={promotionsProducts} isLoading={isLoading} borderColor={borderColor}/>

      </div>
    </div>
   </div>
   <div className='vip-header d-flex align-items-center mt-3'>
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-12 d-flex'>
          <div className='col-6 vip-left'>SON ELANLAR</div>
          <div className='col-6 vip-right d-flex justify-content-end'>
            <Link to={`/search`}>Bütün elanları göstər</Link>
          </div>
        </div>
      </div>
     

    </div>
   </div>

   <div className='container-xxl'>
    <div className='row'>
      <div className='col-12'>

      <InfiniteScroll
                  dataLength={products.length}
                  next={handleNextPage}
                  hasMore={hasMorePages}
                  loader={<div className='text-center my-4'>Yüklənir</div>}
                  endMessage={<div className="text-center my-4">Göstəriləcək daha çox məlumat yoxdur</div>}
                  className="custom-infinite-scroll">
                    <ProductCard products={products} isLoading={false}/>
                </InfiniteScroll>
    

      </div>
    </div>
   </div>
   </div>
   </div>

  )
}

const mapStateToProps = state => {
  return {
    mobileCat: state.mobileCat,
    categories: state.categories,
    homeProducts: state.homeProducts,
    homeScroll: state.homeScroll,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileCat: () => dispatch({ type: 'TOGGLE_MOBILE_CAT' }),
    setCategoryData: (newCategoryData) => dispatch({ type: 'SET_CAT_DATA', payload: newCategoryData }),
    setHomeProductsData: (homeProducts) => dispatch({ type: 'SET_HOME_PRODUCTS_DATA', payload: homeProducts }),
    setHomeScroll: (homeScroll) => dispatch({ type: 'SET_HOME_SCROLL_DATA', payload: homeScroll }),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
