// Import necessary modules from React and react-router-dom
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

// Import components and pages used in the application
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Favorite from './pages/Favorite';
import ActionInfo from './pages/ActionInfo';
import UserActivation from './pages/UserActivation';
import Rules from './pages/Rules';
import Privacy from './pages/Privacy';
import TermsConditions from './pages/TermsConditions';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordReset from './pages/ForgotPasswordReset';
import Register from './pages/Register';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import BuyPackage from './pages/BuyPackage';
import YeniElan from './pages/YeniElan';
import SingleProduct from './pages/SingleProduct';
import ProdileDetails from './pages/ProdileDetails';
import MyProducts from './pages/MyProducts';
import MyVipProducts from './pages/MyVipProducts';
import Notifications from './pages/Notifications';
import PaymentHistory from './pages/PaymentHistory';
import Abunelikler from './pages/Abunelikler';
import Resetpassword from './pages/Resetpassword';
import Logout from './pages/Logout';
import Search from './pages/Search';
import ScrollToTop from './components/ScrollToTop';
import Stores from './pages/Stores';
import Spage from './pages/Spage';
import Success from './pages/Success';
import Error from './pages/Error';
import Productvipup from './pages/Productvipup';


import Updateproduct from './pages/Updateproduct';
import VipProducts from './pages/VipProducts';


import Business from './pages/Business';
import BusinessUpdate from './pages/BusinessUpdate';
import BusinessCreate from './pages/BusinessCreate';

// Define the main component for the application
function App() {
  return (
    <>
     <Provider store={store}>
      {/* Set up the BrowserRouter to enable routing */}
      <BrowserRouter>
      <ScrollToTop/>
        {/* Define the different routes of the application */}
        <Routes>
          {/* The root route with the 'Layout' component as the layout for nested routes */}
          <Route path='/' element={<Layout />}>
            {/* The default route for the root path */}
            <Route index element={<Home />} />
            {/* Define other routes and their corresponding components */}
            <Route path='about' element={<About />} />
            <Route path='actioninfo/:any' element={<ActionInfo />} />
            <Route path='useractivation/:any' element={<UserActivation />} />
            <Route path='rules' element={<Rules />} />
            <Route path='faq' element={<Faq />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='term-condition' element={<TermsConditions />} />
            <Route path='profile-details' element={<ProdileDetails/>}/>
            <Route path='myproducts' element={<MyProducts/>}/>
            <Route path='myvipproducts' element={<MyVipProducts/>}/>
            <Route path='notifications' element={<Notifications/>}/>
            <Route path='payment-history' element={<PaymentHistory/>}/>
            <Route path='abunelikler' element={<Abunelikler/>}/>
            <Route path='profile-details/reset-password' element={<Resetpassword/>}/>
            <Route path='contact' element={<Contact />} />
            <Route path='buy' element={<BuyPackage />} />
            <Route path='new-announcement' element={<YeniElan />} />
            <Route path="logout" element={<Logout/>}></Route>
            <Route path='spage/:any' element={<Spage />} />
            <Route path='favorite' element={<Favorite />} />
            <Route path='stores' element={<Stores />} />
            <Route path='success' element={<Success />} />
            <Route path='error' element={<Error />} />
            <Route path='business' element={<Business/>}/>
            <Route path='businesscreate' element={<BusinessCreate/>}/>
            <Route path='businessupdate/:any' element={<BusinessUpdate/>}/>
            <Route path="updateproduct/:id" element={<Updateproduct/>}></Route>      
            <Route path="vipproducts" element={<VipProducts/>}></Route>      
            
            

            {/* A dynamic route using ':id' which will be passed to 'SingleProduct' */}
            <Route path="product/:id" element={<SingleProduct />} />
            <Route path="productvipup/:id" element={<Productvipup />} />




            


            <Route path='search' element={<Search />} />
          </Route>
          {/* Routes for separate pages that don't use the 'Layout' component */}
          <Route path='login' element={<Login />} />
          <Route path='forgotpassword' element={<ForgotPassword />} />
          <Route path='forgotpasswordreset/:any' element={<ForgotPasswordReset />} />

          <Route path='register' element={<Register />} />






        </Routes>
      </BrowserRouter>
      </Provider>
    </>
  );
}

// Export the main 'App' component
export default App;