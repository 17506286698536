import React from "react";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaEye,FaEyeSlash } from "react-icons/fa";



import { ReactComponent as Animationleft } from "../Images/animation-left.svg";
import { ReactComponent as Animationright } from "../Images/animation-right.svg";
import { ReactComponent as GoogleIcon } from "../Images/Google.svg";
import { ReactComponent as Logo } from "../Images/logo.svg";
import { ReactComponent as RenterMMc } from "../Images/rentermmc.svg";
import { ReactComponent as EllipseLeft } from "../Images/Ellipse-left.svg";
import { ReactComponent as EllipseRight } from "../Images/Ellipse-right.svg";
import { ReactComponent as CloseEye } from "../Images/closeeye.svg";
import bck_log_1 from "../Images/bckimages.png";
import bck_log_2 from "../Images/bckimage2.png";
import bck_log_3 from "../Images/bckimage3.png";

import Meta from "../components/Meta";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    loginRequest();
  };

  async function loginRequest() {
    try {
      const response = await fetch("https://rentermmc.com:8443/api/auth", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data.token) {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.userModel));
          localStorage.setItem("refreshToken", data.data.refreshToken);
          dispatch(setUser(data.data.userModel));
          console.log(data.data.token);

          toast.success("Profilinizə uğurla daxil oldunuz");
          navigate("/");
        } else {
          toast.error("Məlumatlarınız yalnışdır");
        }
      } else {
        toast.error("Məlumatlarınız yalnışdır");
        throw new Error("error");
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className='login_register_walper'>
<Meta title="Daxil ol Renter MMC"/>

      <div className="login-page d-flex">
        <div className="col-md-6 only_desk login-left-side">
          <div className="d-flex align-items-center p-3">
            <Link to="/"><Logo /></Link>
            <div className="line"></div>
            <Link to="/"><RenterMMc /></Link>
          </div>
          <div className="row h-25">
            <div className="col-6 p-5 position-relative">
              <EllipseLeft className="position-absolute"  height={200}  />
              <img src={bck_log_1} alt="" height={200}  className="position-absolute" />
            </div>
            <div className="log-text col-6 d-flex flex-column justify-content-center">
              <span>Hər növ maşınların</span>
              <span className="text-white">Kirayəsi</span>
            </div>
          </div>
          <div className="row h-25">
            <div className="log-text col-6 d-flex flex-column justify-content-center">
              <span>Hər növ evlərin və obyektlərin</span>
              <span className="text-white">Kirayəsi</span>
            </div>
            <div className="col-6 p-5 position-relative">
              <EllipseRight className="position-absolute ellipse-right" height={200} />
              <img src={bck_log_2} alt="" height={200}  className="position-absolute" />
            </div>
          </div>
          <div className="row h-25">
            <div className="col-6 p-5 position-relative">
              <EllipseLeft className="position-absolute" height={200} />
              <img src={bck_log_3} alt="" height={200} className="position-absolute" />
            </div>
            <div className="log-text col-6 d-flex flex-column justify-content-center">
              <span>Hər növ geyimlərin</span>
              <span className="text-white">Kirayəsi</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 login-right-side">
          <div className="login-rightside-animations">
            <Animationleft />
            <Animationright />
          </div>
          <div className="login-page-brand d-flex justify-content-center">
            RENTER<div className="text-black">MMC</div>
          </div>
          <div className="login-details">
      {/*      <button className="login-google">
              <GoogleIcon className="me-4" />
              Login through Google
            </button>
 
            <div className="login-or">
              <div className="left-or-line"></div>
              <div>or</div>
              <div className="right-or-line"></div>
            </div>
 */}

            <ToastContainer  /> 
            <div className="login-password">
              <form onSubmit={submitHandler}>
                <label for="emailadress" className="login-labels">
                  Elektron-poçt*
                </label>
                <br></br>
                <input
                  type="text"
                  className="login-email"
                  id="emailadress"
                  name="account-name"
                  value={username}
                  onChange={usernameHandler}
                />
                <br></br>
                <div className="position-relative">
                  <label for="password" className="login-labels">
                    Şifrə*
                  </label>
                  <br></br>
                  <input
          type={showPassword ? "text" : "password"}
          className="login-password"
          id="password"
          name="password"
          value={password}
          onChange={passwordHandler}
        />
  
          {showPassword ? <FaEyeSlash  className="fs-4 input_icon position-absolute icon-place mt-1"
          onClick={togglePasswordVisibility} /> : <FaEye className="fs-4 input_icon position-absolute icon-place mt-1"
          onClick={togglePasswordVisibility} />} 
                </div>
                <div className="d-flex justify-content-center mt-3 reset-password">
                  Şifrənizi unutmusunuz?  <Link to="/forgotpassword"><span>Yenilə</span></Link>
                </div>
                <div className="d-flex justify-content-center mt-3 reset-password">
                 Hesabınız yoxdur? <Link to="/register"><span>Qeydiyyat</span></Link>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="login-submit" type="submit">
                    Daxil ol
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
