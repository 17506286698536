import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://rentermmc.com:8443/'
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const { data } = await axios.get(
            "https://rentermmc.com:8443/api/auth/get-token-by-refresh-token",
            {
              headers: {
                "access-control-allow-origin": "*",
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'refresh_token': localStorage.getItem("refreshToken")
              },
            }
          );

          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.userModel));
          localStorage.setItem("refreshToken", data.data.refreshToken);
          originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          cleanupLocalStorage();
          return Promise.reject(refreshError);
        }
      } else {
        cleanupLocalStorage();
      }
    }
    return Promise.reject(error);
  }
);

function cleanupLocalStorage() {
  window.location.href = '/login';
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
}

export default axiosInstance;
