import React from "react";
import { ReactComponent as GoldPack } from "../Images/gold-package.svg";
import { ReactComponent as SilverPack } from "../Images/silver-package.svg";
import { ReactComponent as BusinessPack } from "../Images/business-package.svg";
import { ReactComponent as Check } from "../Images/check-icon.svg";
import axiosInstance from '../axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Meta from "../components/Meta";

const BuyPackage = () => {

  async function payment(type) {
    try {
      const response = await axiosInstance.get(`/api/Payment/payment?packet_code=${type}`);

      if (response) {
        const data = response.data;
        const redirectUrl = response.data.data.redirect_url;
        console.log(redirectUrl);
        window.location.href = redirectUrl;



      } else {
        throw new Error("error");
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.messages) {
        const errorMessages = error.response.data.messages[0];
        toast.error(errorMessages);
      } else {
        console.log(error.message);
      }
    }
  }




  return (
    <div className="container_m">
      <div className="container_n">
      <Meta title="Paketlər Renter MMC "/>

        <div className="buy-packages">

          <ToastContainer />

          <div className="col-12 my-5">
            <div className="row">
              <div className="col-12 col-md-4 d-flex mb-3  align-items-center justify-content-center">
                <div className="silver-package">
                  <SilverPack className="gold-pack-svg" />
                  <div className="d-flex header align-items-center">
                    <div className="package-icon d-flex me-3  align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="38"
                        viewBox="0 0 19 38"
                        fill="none"
                      >
                        <path
                          d="M19 0C13.9609 -6.00908e-08 9.12816 2.00178 5.56497 5.56497C2.00178 9.12816 4.40533e-07 13.9609 0 19C-4.40533e-07 24.0391 2.00178 28.8718 5.56497 32.435C9.12816 35.9982 13.9609 38 19 38L19 19V0Z"
                          fill="#8A53FE"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="38"
                        viewBox="0 0 19 38"
                        fill="none"
                      >
                        <path
                          d="M-1.90735e-06 38C5.03911 38 9.87184 35.9982 13.435 32.435C16.9982 28.8718 19 24.0391 19 19C19 13.9609 16.9982 9.12817 13.435 5.56498C9.87184 2.00178 5.03912 2.5942e-06 5.22941e-06 2.15366e-06L-2.46316e-07 19L-1.90735e-06 38Z"
                          fill="#B8B1FF"
                        />
                      </svg>
                    </div>
                    GÜMÜŞ PAKET
                  </div>
                  <div className="description">
                    Siz də elanlarınızı bizim səhifədə görmək istəyirsinizsə, sizə
                    uyğun paketi əldə edin.
                  </div>
                  <div className="price">
                    AZN<span className="price-description">30</span> <span className="price-details">/aylıq</span>
                  </div>
                  <div className="include">Nələr daxildir:</div>
                  <ul className="list-packs">
                    <li><Check className="me-2 buy-packages-icon" />10 Elan </li>
                    <li><Check className="me-2 buy-packages-icon" />1 Günlük  3 VIP Elan</li>
                    <li><Check className="me-2 buy-packages-icon" />30 Gün</li>
                  </ul>
                  <button className="buy-this-package d-flex align-items-center justify-content-center" onClick={() => payment('silver')}>
                    Paketi al
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 d-flex mb-3 align-items-center justify-content-center">
                <div className="gold-package ">
                  <GoldPack className="gold-pack-svg" />
                  <div className="d-flex header align-items-center">
                    <div className="package-icon me-3  d-flex  align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                      >
                        <rect width="18.4865" height="38" fill="#8A53FE" />
                        <rect
                          x="18.4883"
                          width="19.5135"
                          height="38"
                          fill="#B8B1FF"
                        />
                        <rect
                          x="18.4883"
                          y="18.4863"
                          width="19.5135"
                          height="19.5135"
                          fill="#DCD8FF"
                        />
                      </svg>
                    </div>
                    QIZIL PAKET
                  </div>
                  <div className="description">
                    Siz də elanlarınızı bizim səhifədə görmək istəyirsinizsə, sizə
                    uyğun paketi əldə edin.
                  </div>
                  <div className="price">
                    AZN<span className="price-description">50</span> <span className="price-details">/aylıq</span>
                  </div>
                  <div className="include">Nələr daxildir:</div>
                  <ul className="list-packs">
                    <li><Check className="me-2 buy-packages-icon" />20 Elan </li>
                    <li><Check className="me-2 buy-packages-icon" />5 Günlük  5 VIP Elan</li>
                    <li><Check className="me-2 buy-packages-icon" />30 Gün</li>
                  </ul>
                  <button className="buy-this-package d-flex align-items-center justify-content-center" onClick={() => payment('gold')}>
                    Paketi al
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 d-flex mb-3  align-items-center justify-content-center">
                <div className="silver-package ">
                  <BusinessPack className="gold-pack-svg" />
                  <div className="d-flex header align-items-center"><div className="package-icon me-3  d-flex  align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="46"
                      viewBox="0 0 44 46"
                      fill="none"
                    >
                      <path
                        d="M43.1654 33.6464L43.1746 33.6413H43.1565L32.5303 27.8833L21.8863 22.1152L11.242 27.8833L0.615739 33.6413H0.597656L0.606908 33.6464L0.597656 33.6515H0.615739L11.242 39.4094L21.8863 45.1775L32.5303 39.4094L43.1565 33.6515H43.1746L43.1654 33.6464Z"
                        fill="#DCD8FF"
                      />
                      <path
                        d="M43.1654 11.5312L43.1746 11.5265H43.1565L32.5303 5.76811L21.8863 0L11.242 5.76811L0.615739 11.5265H0.597656L0.606908 11.5312L0.597656 11.5362H0.615739L11.242 17.2946L21.8863 23.0627L32.5303 17.2946L43.1565 11.5362H43.1746L43.1654 11.5312Z"
                        fill="#8A53FE"
                      />
                      <path
                        d="M0.617188 11.0625V34.1151L21.8878 22.589L0.617188 11.0625Z"
                        fill="#B8B1FF"
                      />
                      <path
                        d="M43.1569 11.0625V34.1151L21.8867 22.589L43.1569 11.0625Z"
                        fill="#B8B1FF"
                      />
                    </svg>
                  </div>
                    BİZNES PAKET

                  </div>
                  <div className="description">
                    Siz də elanlarınızı bizim səhifədə görmək istəyirsinizsə, sizə
                    uyğun paketi əldə edin.
                  </div>
                  <div className="price">
                    AZN<span className="price-description">100</span> <span className="price-details">/aylıq</span>
                  </div>
                  <div className="include">Nələr daxildir:</div>
                  <ul className="list-packs">
                    <li><Check className="me-2 buy-packages-icon" />Limitsiz Elan</li>
                    <li><Check className="me-2 buy-packages-icon" />5 Günlük  10 VIP Elan</li>
                    <li><Check className="me-2 buy-packages-icon" />30 Gün</li>
                    <li><Check className="me-2 buy-packages-icon" />Brend olaraq fərqlənmə nişanı</li>
                  </ul>
                  <button className="buy-this-package d-flex align-items-center justify-content-center" onClick={() => payment('vippacket')}>
                    Paketi al
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyPackage;
