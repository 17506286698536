import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Meta from '../components/Meta';

const Productvipup = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState([]);
  const [product, setProduct] = useState({});
  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[2];

  useEffect(() => {
    subscriptionData();
    getProduct();
  }, []);


  const getProduct = async () => {
    try {
      const response = await axios.get(
        `https://rentermmc.com:8443/api/Product/get-product?LanguageCode=az&Slug=${slug}`
      );
      setProduct(response.data.data);
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };

  const subscriptionData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Promotion/get-user-promotions?languageCode=az&PromotionType=vip`
      );

      const data = response.data;
      setSubscription(data.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };










  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = {};

    for (let [name, value] of formData.entries()) {
      data[name] = value;
    }

    data["productSlug"] = slug;

    console.log("Data to be submitted:", data);

    try {
      const response = await axiosInstance.post('api/Promotion/promote-product', data);

      if (response.status === 200) {
        console.log('Data submitted successfully');
        navigate(`/product/${slug}`);
      } else {
        console.error('Post request failed with status:', response.status);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };



  return (
    <div className="container">
      <div className="col-12">
      <Meta title="VİP et Renter MMC"/>

        <div className="row">


        <div className="col-12 col-md-3 my-3 px-2">
    <div className="details-categories ">


    <div className='col-12 text-center'>
                <div className='row p-3'>
                <img src={product.coverImage} alt="w-100"/>
                   <div className='mt-2'>
                   {product.productTitle}

                   </div>
       </div>
                    </div>
                    </div>
                    </div>




          <div className="col-12 col-md-9 my-3 px-2">
            <div className="details-description">

             <div className='row my-2'>
               <div className='col-6 profile-details-title'>VIP et</div>
               <div className='col-6 text-end'>
               </div>

             </div>

              <div className="col-12 col-md-12">
                <div className="row">
                  <div className="col-12 col-md-12">
                    Paket məlumatları
                  </div>
                  <form onSubmit={handleFormSubmit}>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Elan növü</th>
                        <th scope="col">Aid olduğu paket</th>
                        <th scope="col">Aktivlik müddəti</th>
                        <th scope="col">Status</th>
                        <th scope="col">Tarix</th>
                        <th scope="col">Seçim</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.map((subdata) => (

                        <tr>
                          <th scope="row">1</th>
                          <td> {subdata.promotionTitle}</td>
                          <td>{subdata.promotionPacketTitle}</td>
                          <td>{subdata.dayCount} gün</td>

                          <td><img src={subdata.status} height={20} /></td>
                          <td>{subdata.activatedDate} &nbsp; &nbsp;
                          {subdata.activatedTime}</td>
                          <td><input type='radio' name='promotionUserId' readOnly value={subdata.promotionUserId}/></td>
                        </tr>

                      ))}
                    </tbody>
                  </table>
                  <div className='text-end my-3'>
                    <button type='submit' className='btn btn-primary'>
                      VIP et
                    </button>
                  </div>
                  </form>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productvipup;
