import React from 'react'
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
            <div class="alert alert-danger m-5" role="alert">
                Xəta
            </div>
       </div>
      </div>
    </div>
    </>
  )
}

export default About