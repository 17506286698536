import React from 'react';

const About = () => {
  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[2];

  const textMap = {
    regsuccess: "Qeydiyyatınız bitdi, profilinizi aktifləşdirmək üçün zəhmət olmasa maile daxil olun",
    forgetsuccess: "Şifrə yeniləmə linki elektron poçtunuza göndərildi",

  };

  const displayText = textMap[slug] || "";

  return (
    <div className='container_m'>
      <div className='container_n'>
        <div className='row'>
          <div className='col-12'>
            <div className="alert alert-success text-center my-5" role="alert">
              {displayText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
