import React from 'react'
import { NavLink } from 'react-router-dom'
const NavFooter = () => {
  return (
<>
<div className='navigation-footer'>
<div className='container_m'>
<div className='container_n'>

    <div className='row'>
        

            <ul className='d-flex'>
                <li><NavLink to='/about'>Haqqımızda</NavLink></li>
                <li><NavLink to='/rules'>Qaydalar</NavLink></li>
                <li><NavLink to='/term-condition'>İstifadəçi razılaşması </NavLink> </li>
                <li><NavLink to='/privacy'>Məxfilik siyasəti</NavLink></li>
                <li><NavLink to='/faq'>FAQ</NavLink></li>
                <li><NavLink to='/contact'>Bizimlə əlaqə</NavLink></li>
            </ul>
        
            </div>
    </div>
</div></div>
</>
  )
}

export default NavFooter