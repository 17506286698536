import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import ProductCard from '../components/MyProductCard';
import Meta from '../components/Meta';

import UserDetails from "../components/UserDetails";
const MyVipProducts = () => {

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false); // New state for isFetching
  let page = 0;
  let lang = "az";

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const handleScroll = () => {
    if (!isFetching) {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const bottomPosition = document.documentElement.scrollHeight - window.innerHeight;
      if (scrollPosition >= bottomPosition - 200) {
        setIsFetching(true); // Use setIsFetching to update the state
      }
    }
  };


  useEffect(() => {


  const ProductData = async () => {
    setIsLoading(true);
    setProducts([])
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Product/get-user-promoted-products?LanguageCode=${lang}&pageIndex=0&PromotionType=vip`
      );

      const data = response.data; // Assuming the response data is already in JSON format
      setProducts(prevProducts => [...prevProducts, ...data.data.items]);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      setIsFetching(false); // Reset the isFetching state to allow the next fetch
      page++;
    }
  };

  ProductData();

}, []);





  return (
    <div className="container_m">
    <div className="container_n">
    <Meta title="VİP məhsullarım Renter MMC"/>

    <div className="col-12">
      <div className="row">
          <UserDetails />
          <div className="col-12 col-lg-9 my-3 px-2">
          <div className="details-description">

            <div className="row profile-details-title">
              VIP Elanlarım
            </div>




<div>

         
          {console.log(products)}
          <ProductCard products={products} lang={lang} isLoading={isLoading} />
</div>



            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default MyVipProducts;