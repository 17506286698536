import React, { useState, useEffect } from 'react';
import ProductCard from '../components/ProductCard';
import axios from "axios";
import { FaMapMarkerAlt,FaPhoneAlt,FaRegClock } from "react-icons/fa";
import Meta from '../components/Meta';

const About = () => {
  const [products, setProducts] = useState([]);
  const [store, setStore] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[2];

  let isFetching = false;
  let page = 0;

  useEffect(() => {
    StoreData();
    ProductData();
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll); };
  }, []);

  const handleScroll = () => {
    if (!isFetching) {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const bottomPosition = document.documentElement.scrollHeight - window.innerHeight;
      if (scrollPosition >= bottomPosition - 200) {
        isFetching = true;
        ProductData();
      }
    }
  };


  const StoreData = async () => {
    try {
      const response = await axios.get(
        `https://rentermmc.com:8443/api/Store/get-store-detail?languageCode=az&StoreSlug=${slug}`
      );
      setStore(response.data.data);
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };

  const ProductData = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://rentermmc.com:8443/api/Product/search?LanguageCode=az&StoreSlug=${slug}&pageIndex=${page}&pageSize=15`

      );

      const data = await response.json();
      setProducts(prevProducts => [...prevProducts, ...data.data.items]);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      isFetching = false;
      page++;
    }
  };

  return (
    <div className='container_m'>
    <div className='container_n'>
    <Meta title={store.storeTitle}/>

      <div className='row'>
        <div className='col-12 my-3'>




          <div className="store_card">
            <div className='only_desk p-2'>
              <div className='row'>
                <div className='col-12 col-md-6 d-flex justify-content-end align-items-center'>
                    <div className='col-md-12 text-end '>
                      <div className='row my-2'>
                        <div className='col-md-9'>
                          <div className='color_w p-2'>{store.storeAddress}</div>
                        </div>
                        <div className='col-md-3'>
                          <div className='store_card_icon_box p-2 text-start'><FaMapMarkerAlt className='fs-3' /></div>
                        </div>
                      </div>
                      <div className='row my-2'>
                        <div className='col-md-9'>
                        <div className='color_w p-2'>{store.storePhones}</div>
                        </div>
                        <div className='col-md-3'>
                        <div className='store_card_icon_box p-2 text-start'><FaPhoneAlt className='fs-3' /></div>
                        </div>
                      </div>
                      <div className='row my-2'>
                        <div className='col-md-9'>
                        <div className='color_w p-2'>{store.workHours}</div>
                        </div>
                        <div className='col-md-3'>
                        <div className='store_card_icon_box p-2 text-start'><FaRegClock className='fs-3' /></div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 text-end'>
                  <img src={store.storeCover} width={200} height={200} className="store_card_img_top" alt={store.storeTitle} />
                  <h5 className="store_card_title mt-3">{store.storeTitle}</h5>
                  <p className="card-text">{store.storeDescription}</p>
                </div>

                </div>
              </div>


              <div className="only_mobile p-2">
              <div className='row'>
                <div className='col-12'>
                      <div className='row'>
                        <div className='col-6 d-flex justify-content-end align-items-center'>

                            <h4>{store.storeTitle}</h4>
                        </div>
                        <div className='col-6 text-end'>
                        <img src={store.storeCover} width={150} height={150} className="store_card_img_top" alt={store.storeTitle} />
                        </div>
                      </div>
                      <div className='col-12  text-end'>
                        <div className='mt-2'><h5>{store.storeDescription}</h5></div>
                        <div className='p-2'>{store.storeAddress} <FaMapMarkerAlt className='fs-3' /></div>
                        <div className='p-2'>{store.storePhones} <FaPhoneAlt className='fs-3' /></div>
                        <div className='p-2'>{store.workHours} <FaRegClock className='fs-3' /></div>
                      </div>

                  </div>
                </div>
    
              </div>


            </div>



       


          </div>




          <div className='col-12'>
            <ProductCard products={products} isLoading={isLoading} />
          </div>
        </div>
      </div>
      </div>
  )
}

export default About