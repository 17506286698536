import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:type" content={props.ogType || 'website'} />
      <meta property="og:image" content={props.ogImage} />
      <meta property="og:url" content={props.url || window.location.href} />

    </Helmet>
  );
};

export default Meta;
