import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';

const UserActivation = () => {

    const url = window.location.pathname;
    const segments = url.split('/');
    
    useEffect(() => {
      activeFetch();
      console.log("isledi");
    }, []);


    const activeFetch = async () => {
      try {
        const response = await fetch(
          `https://rentermmc.com:8443/api/auth/check-token-validity?Token=${segments[2]}`
        );
        const data = await response.json();
        console.log(data);
        console.log(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };






  return (
    <>
    <div className='container'>
<div class="alert alert-success m-5" role="alert">
  Profiliniz aktiv oldu artıq profilinizə daxil ola bilərsiniz.
  </div>

  <div className='text-center mb-5'>
   <Link to="/login" className='primary_btn'>Profilə daxil ol</Link>
  </div>
</div>
    </>
  )
}

export default UserActivation