import React, { useState, useEffect, useRef } from "react";
import { FaExclamation,FaImages } from "react-icons/fa"; // You can import icons from react-icons
import { BsTrash } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Meta from "../components/Meta";

const YeniElan = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [paramValues, setParamValues] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  const [subParamValues, setSubParamValues] = useState();


  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch categories data
  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://rentermmc.com:8443/api/Category/get-all-categories?LanguageCode=az"
      );
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchParameters = async (categoryId) => {
    try {
      let fetchUrl =
        "https://rentermmc.com:8443/api/Category/get-parameters?LanguageCode=az&RequestFrontType=add";
      if (categoryId) {
        fetchUrl += `&CategoryId=${categoryId}`;
      }
      const response = await fetch(fetchUrl);
      const data = await response.json();
      setParamValues(data.data);
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };


    // Fetch Parameters Data
    const fetchSubParameters = async (parameterKey,mask) => {
      try {
        let fetchUrl =`https://rentermmc.com:8443/api/Category/get-child-masks?LanguageCode=az&ParameterKey=${parameterKey}&Mask=${mask}`;
  
        const response = await fetch(fetchUrl);
        const data = await response.json();
        setSubParamValues(data.data);
      } catch (error) {
        console.error("Error fetching parameters:", error);
      }
    };


    useEffect(() => {
      if (subParamValues && subParamValues.parameterMasks) {
        const maskOptions = subParamValues.parameterMasks.map((mask) => mask.parameterMaskData);
        const selectElement = document.getElementById(subParamValues.parameterKey);
        selectElement.innerHTML = '';
    
        maskOptions.forEach((mask) => {
          const option = document.createElement('option');
          option.value = mask;
          option.textContent = mask;
          selectElement.appendChild(option);
        });
      }
    }, [subParamValues]);
    




    

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    setSelectedCategory(selectedCategoryId);
    fetchParameters(selectedCategoryId);
  };

  const handleParameterChange = (event) => {
    const parameterKey = event.target.name;
    const mask = event.target.value;
    console.log('Hazirdi',parameterKey,mask)
    fetchSubParameters(parameterKey,mask);
  };



  // Handle file input change and upload
  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    try {
      const uploadedImageUrl = await uploadFile(selectedFile);
      if(uploadedImageUrl!=null){
      setImageUrls([...imageUrls, uploadedImageUrl]);
    }setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false); // Set loading to false in case of an error
    }
  };

  // Upload the file to the server
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      const response = await fetch(
        "https://rentermmc.com:8443/api/Product/add-image",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      const uploadedImageUrl = data.data;
      console.log(data.messages)

      if(data.isSuccessful==true){
        console.log("File uploaded successfully");
        return uploadedImageUrl;
      }else{
        toast.error(data.messages[0]);
        return null
      }
     
    } catch (error) {
      if (error.response && error.response.data && error.response.data.messages) {
        toast.error(error.response.data.messages[0]);
      } else {
        toast.error("Gözlənilməyən xəta baş verdi.");
      }
      throw error;
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = {
      parameters: {},
    };

    for (let [name, value] of formData.entries()) {
      if (
        name === "ProductTitle" ||
        name === "CategoryId" ||
        name === "Description" ||
        name === 'fullName' ||
        name === 'email' ||
        name === 'phone'
      ) {
        data[name] = value;
      } else if (name === "files") {
      } else {
        const paramName = name;
        const paramValue = value;
        data.parameters[paramName] = paramValue;
      }
    }

    data["images"] = imageUrls;

    console.log("Data to be submitted:", data);

    try {
      const response = await axiosInstance.post('api/Product/add-product', data);

      if (response.status === 200) {
        console.log('Data submitted successfully');
        navigate('/');
      } else {
        console.error('Post request failed with status:', response.status);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  // Generate category options for the select input
  const renderCategoryOptions = (categories, level = 0) => {
    return categories.map((category) => {
      const indentation = "\u00A0".repeat(level * 3);
      if (category.childCategories.length > 0) {
        return (
          <React.Fragment key={category.categoryId}>
            <option className="bold-option" disabled>
              {indentation} {category.categoryTitle} &#11167;
            </option>
            {renderCategoryOptions(category.childCategories, level + 1)}
          </React.Fragment>
        );
      } else {
        return (
          <option value={category.categoryId} key={category.categoryId}>
            {indentation}
            {category.categoryTitle}
          </option>
        );
      }
    });
  };

  const handleDeleteImage = (imageUrl) => {
    setImageUrls((prevImageUrls) =>
      prevImageUrls.filter((url) => url !== imageUrl)
    );
  };

  // Generate parameter inputs based on the fetched paramValues
  const renderParameterInputs = () => {
    return paramValues.map((param, index) => {
      let input;

      if (param.parameterMasks !== null && param.parameterMasks.length > 0) {
        const options = param.parameterMasks.map((mask) => (
          <option
            key={mask.parameterMaskId}
            value={mask.parameterMaskData}
            data-key={mask.parameterMaskId}
          >
            {mask.parameterMaskData}
          </option>
        ));

        input = (
          <select
            className="form-control mb-2"
            name={param.parameterKey}
            id={param.parameterKey}
            key={param.parameterId}
            {...(param.isRequired === 1 && { required: true })}
            onChange={handleParameterChange}
          >
            <option value=""> {param.parameterTitle} seçin</option>
            {options}
          </select>
        );
      } else {
        const inputType =
          param.parameterTypeTitle === "int" ? "number" : "text";

          input = (
            <input
              className="form-control"
              type={inputType}
              step=".01"
              name={param.parameterKey}
              key={param.parameterId}
              {...(param.isRequired === 1 && { required: true })}
            />
          );
      }

      return (
        <React.Fragment key={index}>
          <div className="col-12 col-md-4 mt-3">
            <label>{param.parameterTitle} {param.isRequired === 1 && <span className="color_red">*</span>}</label>
            {input}
          </div>
        </React.Fragment>
      );
    });
  };


  const renderSubParameterInputs = () => {
    if (!subParamValues) {
      return null;
    }
  
    let param = subParamValues;
  
    let input;
  
    if (param.parameterMasks !== null && param.parameterMasks !== undefined && param.parameterMasks.length > 0) {
      const options = param.parameterMasks.map((mask) => (
        <option key={mask.parameterMaskId} value={mask.parameterMaskData} data-key={mask.parameterMaskId}>
          {mask.parameterMaskData}
        </option>
      ));
  
      input = (
        <select className="form-control mb-2" name={param.parameterKey} key={param.parameterId}>
          <option value=""> {param.parameterTitle} seçin</option>
          {options}
        </select>
      );
    } else {
      const inputType = param.parameterTypeTitle === "integer" ? "number" : "text";
  
      input = (
        <input className="form-control mb-2" type={inputType} name={param.parameterKey} key={param.parameterId} placeholder={param.parameterTitle} />
      );
    }
  
    return (
      <div className="col-3 mt-3">
        {input}
      </div>
    );
  };
  







  return (
    <div className="container_m">
      <div className="container_n">
      <Meta title="Yeni elan Renter MMC"/>

      <ToastContainer  /> 

        <div className="row">
          <form onSubmit={handleFormSubmit}>
            <div className="col-12">
              <div className="new-announce">
                <div className="announce-articles">
                  <div className="announce-title">YENİ ELAN</div>


                  <div className="add_form_section">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <h2 className="add_form_section_title">Yeni elanın ilkin detalları</h2>

   
                  <div className="row">
                    <div className="col-12 col-md-4 mt-3">
                      <label>Kateqoriya*</label>

                      <select name="CategoryId" className="form-select" onChange={handleCategoryChange} value={selectedCategory}>
                        <option value="">Select a category</option>
                        {renderCategoryOptions(categories)}
                      </select>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <label>Məhsul adı*</label>
                      <input type="text" className="form-control" name="ProductTitle" />
                    </div>


                    {renderParameterInputs()}

                 
                    <div className="row mb-3">
                    <div className="col-12 col-md-12 mt-3">
    
                      <label>Qeyd*</label>
                      <textarea className="form-control" name="Description">

                      </textarea>

                    </div>


                    <div className="col-12 col-md-12 mt-3">
                      <div className="row">
                      <div className="col-12 col-md-3">
                      <label>Elann Şəkilləri*</label>


                      <button
                      type="button"
                      className="btn btn-light add_form_img_add_btn"
                      onClick={handleButtonClick}
                    > 
                    <FaImages  className="fs-1"/>
                    <br/>
                      Şəkil əlavə et
                    </button>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      name="files"
                      onChange={handleFileChange}
                      className="d-none"
                    />
                    </div>
                    <div className="col-12 col-md-9">
                    <div className="row mt-3" id="image-container">
                      {imageUrls.map((imageUrl) => (
                        <div className="col-6 col-md-3">
                          <div className="img_upload_box text-center">
                            <img
                              src={imageUrl}
                              key={imageUrl}
                              className="img_upload_box_img"
                            />
                            <div className="img_upload_box_delete">
                              <button
                                type="button"
                                className="btn btn-danger mt-2"
                                onClick={() => handleDeleteImage(imageUrl)}
                              >
                                <BsTrash />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      {loading && (
                        <div className="col-6 col-md-3">
                          <div className="img_upload_box text-center">
                            {/* <Spinner animation='border' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                              </Spinner> */}
                            Yüklənir...
                          </div>
                        </div>
                      )}
                    </div>

                    </div>

                    </div>

                    </div>

<div className="col-12 mt-2">
<div class="alert alert-warning d-flex align-items-center" role="alert">
  <FaExclamation className="fs-4"/>
  <div>
  Daxil etdiyiniz ilk şəkil elanınız üçün əsas şəkil olaraq qəbul olunacaqdır. Bu səbəbdən ilk daxil edecəyiniz şəkli buna əsasən seçin.
  </div>
</div>
</div>

                    </div>









                    </div>
                    

                 </div>
                 </div>
</div>


                  <div className="add_form_section">
                <div className="row">

    {/*              <div className="col-12 col-md-6">
                    <h2 className="add_form_section_title">Əlaqə məlumatları</h2>
                  <div className="col-12 col-md-8 mt-2">
                    <label for="fullName">Adınız*:</label>
                    <input placeholder="Adınızı daxil edin......" className="form-control" id="fullName" type="text" name="fullName" />
                  </div>
                  <div className="col-12 col-md-8 mt-2">
                    <label for="email">Email*:</label>
                    <input placeholder="Emailinizi daxil edin...." className="form-control" id="email" type="email" name="email"></input>
                  </div>
                  <div className="col-12 col-md-8 mt-2">
                    <label for="phone">Əlaqə nömrəsi*:</label>
                    <input placeholder="(+994 50) xxx xx xx" className="form-control" id="phone" type="tel" name="phone"></input>
                    <br></br>
                    <br></br>
                  </div>
                  </div>
*/}
                    
                <div className="col-12 col-md-6">
                    <h2 className="add_form_section_title">RenttMMC-nin istifadəçi qaydaları</h2>

              

                  <div className="d-flex align-items-center">
                    <div className="me-2 circle"></div>
                    <div className="accordion-content-inner">Eyni elanı bir neçə dəfə təqdim etməyin.</div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="me-2 circle"></div>
                    <div className="accordion-content-inner">
                      Təsvir və ya şəkillərdə telefon, email və ya sayt
                      ünvanı göstərməyin.
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="me-2 circle"></div>
                    <div className="accordion-content-inner">Uyğun məlumatları uyğun xanalara doldurun.</div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="me-2 circle"></div>
                    <div className="accordion-content-inner">Qadağan olunmuş məhsulları paylaşmayın.</div>
                  </div>
                  <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" className="me-2" required />
                    <div className="accordion-content-inner">
                      Elanı yerləşdirərək, siz RenttMMC-nin{" "}
                      <Link to="/term-condition">
                        İstifadəçi razılaşması
                      </Link>{" "}
                      ilə razı olduğunuzu təsdiq edirsiniz.{" "}
                    </div>
                  </div>
                </div>
                </div>
                </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="announce-btn">Elanı paylaş</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default YeniElan;
