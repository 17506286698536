import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import { FaSearch, FaSlidersH } from "react-icons/fa"; // Import the icon you want to use
import InfiniteScroll from "react-infinite-scroll-component";
import Meta from '../components/Meta';

const Search = ({ lang, toggleFilter }) => {
  const [loaderEnd, setLoaderEnd] = useState(false);
  const [exScroll, setExScroll] = useState(sessionStorage.getItem('searchScroll'));
  const navigate = useNavigate();
  const location = useLocation();
  const [catItems, setCatItems] = useState([]);
  const [paramValues, setParamValues] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [searchScroll, setSearchScroll] = useState(sessionStorage.getItem('searchScroll'));

  const languageCode = 'az';
  
  const initialKeyword = new URLSearchParams(location.search).get('keyword');
  const [getKeyword, setGetKeyword] = useState(initialKeyword || '');


  const initialState = () => {
    const state = {};
    paramValues.forEach((param) => {
      state[param.parameterTitle] = '';
    });
    return state;
  };

  const urlParams = new URLSearchParams(location.search);
  const getParams = {};

  urlParams.forEach((value, key) => {
    if (value !== '') {
      getParams[key] = value;
    }
  });


  const [filters, setFilters] = useState({ ...initialState, ...getParams });



  useEffect(() => {
    window.scrollTo(0, exScroll);
  }, [loaderEnd]);


  useEffect(() => {
    setLoaderEnd(true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('searchScroll', searchScroll);
  }, [searchScroll]);

  useEffect(() => {

    const handleScroll = () => {
      setSearchScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [searchScroll]);




  useEffect(() => {
    parametrsData();
  }, []);

  const handleKeywordChange = (event) => {
    setGetKeyword(event.target.value);
  };




  useEffect(() => {
      const filtersJson = {
        LanguageCode: languageCode,
        ...filters,
        pageIndex: page,
        pageSize: 20
      };
      const queryParams = new URLSearchParams(filtersJson).toString();
      const url = `https://rentermmc.com:8443/api/Product/search?${queryParams}`;
      fetchData(url,true);
  }, [page]);



  





  const handleNextPage = () => {
    if (hasMorePages) {
      setPage(page + 1);
      sessionStorage.setItem('currentSearchPage', parseInt(page+1));
    }
  };






  const catData = async () => {
    try {
      const filtersJson = {
        LanguageCode: languageCode,
        ...filters,
        pageIndex: page,
        pageSize: 20
      };
      const queryParams = new URLSearchParams(filtersJson).toString();
      let url = `https://rentermmc.com:8443/api/Category/get-all-categories-search?${queryParams}`;

      if (filters.CategoryId) {
        url += `&CategoryId=${filters.CategoryId}`;
      }

      const response = await fetch(url);
      const data = await response.json();
      setCatItems(data.data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };


  const fetchData = async (url,new_pew) => {
    if(new_pew==false){
      setProducts([]);
      setPage(0);
    }
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.data.items.length > 0) {
          setProducts((prevProducts) => {
            if(new_pew){
            if (prevProducts) {
              const newProducts = [...prevProducts, ...data.data.items];
              // Filter out duplicate products based on a unique identifier (e.g., productId)
              const uniqueProducts = Array.from(new Set(newProducts.map(product => product.productId)))
                .map(productId => newProducts.find(product => product.productId === productId));
              sessionStorage.setItem('searchProducts', JSON.stringify(uniqueProducts));
              return uniqueProducts;
            } else {
              return data.data.items;
            }}else{
              return data.data.items;
            }
          });
          setIsLoading(true);
          setHasMorePages(true);

        } else {
          setIsLoading(false);
          setHasMorePages(false);

        }
      } catch (error) {
        setHasMorePages(false);
        console.error('Error fetching data:', error);
      } 
    };



    const handleFilterChange = () => {
      const GetCat = new URLSearchParams(location.search).get('CategoryId');
      const filtersJson = {
        LanguageCode: languageCode,
        ...filters,
        pageIndex: page,
        pageSize: 20
      };


      const queryParams = new URLSearchParams(filtersJson).toString();
      setProducts([]);
      const url = `https://rentermmc.com:8443/api/Product/search?${queryParams}`;



      navigate(`?${queryParams}`);



      
      const storedPage = sessionStorage.getItem('currentSearchPage');
      if (storedPage !== null) {
        setPage(parseInt(storedPage));
      }


      const sessionProducts = JSON.parse(sessionStorage.getItem('searchProducts')) || [];
      const exSearchPar = sessionStorage.getItem('SearchPar');


      if(exSearchPar==queryParams){
      if (sessionProducts.length > 0) {
        setProducts(sessionProducts);
        fetchData(url,true);
      }else{
        fetchData(url,false);
      }
      console.log(exSearchPar,queryParams,'eynidi')

      sessionStorage.setItem('SearchPar', queryParams);

    }else{
      console.log(exSearchPar,queryParams,'deyisdi')

      fetchData(url,false);
      sessionStorage.setItem('SearchPar', queryParams);

    }


    
    };


  useEffect(() => {
    catData();
    handleFilterChange();
  }, [filters]);

  const parametrsData = async (C_id) => {
    try {
      let fetchUrl = 'https://rentermmc.com:8443/api/Category/get-parameters?LanguageCode=az&RequestFrontType=search';


      if (C_id) {
        fetchUrl += `&CategoryId=${C_id}`;
      }
      else if (urlParams.has("CategoryId")) {
        fetchUrl += `&CategoryId=${urlParams.get("CategoryId")}`;
      }

      const response = await fetch(fetchUrl);
      const data = await response.json();
      setParamValues(data.data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleCategoryChange = (C_id) => {
    parametrsData(C_id);
    setFilters({
      ...filters,
      CategoryId: C_id,
    });
  };




  const handleParChange = (e) => {
    setProducts([]);
    const { name, value } = e.target;
    if (value === '') {
      const { [name]: deletedValue, ...updatedFilters } = filters;
      setFilters(updatedFilters);
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };


  const handleSelectChange = (e) => {
    const selectedOption = Array.from(e.target.options).find(option => option.selected);
    const key = selectedOption.getAttribute('data-key');

    console.log(key, "Id");
  };





  // Generate parameter inputs based on the fetched paramValues
  const renderParameterInputs = () => {
    return paramValues.map((param, index) => {
      let input;

      if (param.parameterMasks !== null && param.parameterMasks.length > 0) {
        const options = param.parameterMasks.map((mask) => {
          const isSelectedOpt = mask.parameterMaskData === filters[param.parameterKey];
          return (
            <option key={mask.parameterMaskId} value={mask.parameterMaskData} data-key={mask.parameterMaskId} selected={isSelectedOpt ? 'selected' : null} >
              {mask.parameterMaskData}
            </option>
          );
        });


        input = (
          <select className='form-control mb-2' name={param.parameterKey} key={param.parameterId} onChange={handleParChange} >
            <option value=""> {param.parameterTitle} seçin</option>
            {options}
          </select>
        );
      } else {
        const inputType = param.parameterTypeTitle === 'integer' ? 'number' : 'text';

        input = (
          <input className='form-control mb-2'
            type={inputType}
            name={param.parameterKey}
            key={param.parameterId}
            placeholder={param.parameterTitle}
            onChange={handleParChange}
            value={filters[param.parameterKey]} />
        );
      }

      return (
        <React.Fragment key={index}>
          <div className='col-3 mt-3'>{input}</div>
        </React.Fragment>
      );
    });
  };

  return (
    <div className='container'>
      <div className='container_n'>
      <Meta title="Axtarış Renter MMC"/>

        <div className='only_mobile'>
          <form method='GET' action={`/search`} >
            <div className='row'>
              <div className='col-10'>
                <div className="input-group mt-3 mb-3 ps-1 pe-1">

                  <input type="text" className="form-control mobile_searc_input" name="keyword" placeholder="Axtarışınız üzrə məlumatları daxil edin" value={getKeyword} onChange={handleKeywordChange} required />
                  <button type='submit' className="input-group-text mobile_searc_btn" id="basic-addon2"><FaSearch /></button>
                </div>

              </div>

              <div className='col-2 d-flex align-items-center'>


                <button type='button' onClick={toggleFilter} className="input-group-text mobile_searc_btn btn-light p-2" id="basic-addon2"><FaSlidersH /></button>
              </div>
            </div>
          </form>
        </div>

        {catItems.length > 0 ? (
  <div className='card mt-3 mb-3 p-3 px-5 category_card'>
    <div className='row'>
      {catItems.map((f_category) => (
        <div className='col-6 col-md-3 p-2' key={f_category.categoryId}>
          <Link
            onClick={() => handleCategoryChange(f_category.categoryId)}
            className={
              filters.CategoryId === f_category.categoryId
                ? 't_d_n'
                : 't_d_n'
            }
          >
            {f_category.categoryTitle}   ({f_category.count})
          </Link>
        </div>
      ))}
    </div>
  </div>
) : null}



<InfiniteScroll
                  dataLength={products.length}
                  next={handleNextPage}
                  hasMore={hasMorePages}
                  loader={<div className='text-center my-4'>Yüklənir</div>}
                  endMessage={<div className="text-center my-4">Göstəriləcək daha çox məlumat yoxdur</div>}
                  className="custom-infinite-scroll"
                  scrollThreshold={1} >
                    <ProductCard products={products} isLoading={false}/>
                </InfiniteScroll>
    
    
      </div>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    mobileCat: state.mobileCat,
    isFilterOpen: state.isFilterOpen,
    categories: state.categories,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileCat: () => dispatch({ type: 'TOGGLE_MOBILE_CAT' }),
    toggleFilter: () => dispatch({ type: 'TOGGLE_FILTER' }),
    setCategoryData: (newCategoryData) => dispatch({ type: 'SET_CAT_DATA', payload: newCategoryData }),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

