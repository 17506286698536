import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import ProductCard from '../components/MyProductCard';
import InfiniteScroll from "react-infinite-scroll-component";
import UserDetails from "../components/UserDetails";
import Meta from '../components/Meta';
const MyProducts = () => {

  const [productStatus, setProductStatus] = useState([]);
  const [nowStatus, setNowStatus] = useState(1);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);


  let lang = "az";

  useEffect(() => {
    productStatusData();
  }, []);

  const productStatusData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/auth/get-user-product-count?LanguageCode=${lang}`
      );
      const data = response.data;
      setProductStatus(data.data);
    } catch (error) {
      console.error('Error fetching shortCuts items:', error);
    }
  };

  const handleNowStatusChange = (status_id) => {
    setProducts([]);
    setPage(0)
    setNowStatus(status_id);
  };

  const handleNextPage = () => {
    if (hasMorePages) {
      setPage(page + 1);
    }
  };


  const ProductData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/auth/get-user-products?LanguageCode=${lang}&statusType=${nowStatus}&pageIndex=${page}`
      );

      const data = response.data;

      if (data.data.items.length > 0) {
        setProducts(prevProducts => [...prevProducts, ...data.data.items]);
        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }

    } catch (error) {
      setHasMorePages(false);
      console.error('Error fetching data:', error);
    }
  };



  useEffect(() => {

    ProductData();

  }, [nowStatus, page]);





  return (
    <div className="container_m">
      <div className="container_n">
      <Meta title="Məhsullarım Renter MMC"/>

        <div className="col-12">
          <div className="row">
            <UserDetails />
            
            <div className="col-12 col-md-9 my-3 px-2">
              <div className="details-description">

                <div className="profile-details-title">
                  Elanlarım
                </div>

                <div className='col-12 col-xs-12 col-md-12 col-lg-12 mt-2 p-3'>
                  <div className='row'>
                    {productStatus.map((status) => (
                      <button
                        className={`col-6 col-md-3 mb-2 d-flex justify-content-center mp_status_box ${nowStatus == status.id ? "mp_status_box_active" : ""}`}
                        onClick={() => handleNowStatusChange(status.id)} key={status.id}>
                          {status.statusName} ({status.productCount})
                      </button>
                    ))}
                  </div>
               </div>

                <InfiniteScroll
                  dataLength={products.length}
                  next={handleNextPage}
                  hasMore={hasMorePages}
                  loader={<div className='text-center my-4'>Yüklənir</div>}
                  endMessage={<div className="text-center my-4">Göstəriləcək daha çox məlumat yoxdur</div>}
                  className="custom-infinite-scroll">
                    <ProductCard products={products} lang={lang}/>
                </InfiniteScroll>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProducts