import React, { useState, useEffect, useRef } from "react";
import UserDetails from "../components/UserDetails";

import { NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as Location } from "../Images/location.svg";
import { ReactComponent as Clock } from "../Images/clock.svg";
import { ReactComponent as Call } from "../Images/phone.svg";
import axiosInstance from '../axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsTrash } from "react-icons/bs";
import Meta from "../components/Meta";


const Bizneshesab = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [storeCover, setStoreCover] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storesItems, setStoresItems] = useState([]);


  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[2];


  const [storeLogo, setStoreLogo] = useState(null);

  const [storeTitle, setStoreTitle] = useState(null);
  const [storeSlogan, setStoreSlogan] = useState(null);
  const [storeDescription, setStoreDescription] = useState(null);
  const [storeAddress, setStoreAddress] = useState(null);
  const [storeContact, setStoreContact] = useState(null);
  const [workHours, setWorkHours] = useState(null);
  



  const StoreData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Store/update-store?StoreSlug=${slug}`
      );

      const sdata = await response.data;
      setStoresItems(sdata.data);
      console.log(setStoresItems);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };




  useEffect(() => {
    StoreData();
}, []);


useEffect(() => {
    setStoreLogo(storesItems.storeLogo)
    setStoreTitle(storesItems.storeTitle);
    setStoreSlogan(storesItems.storeSlogan);
    setStoreDescription(storesItems.storeDescription);
    setStoreAddress(storesItems.storeAddress);
    setStoreContact(storesItems.storeContact);
    setWorkHours(storesItems.workHours);
    
  
}, [storesItems]);




  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = {};

    for (let [name, value] of formData.entries()) {

        data[name] = value;

    }

    data["storeLogo"] = storeLogo; 
    data["storeCover"] = storeLogo;
    data["StoreSlug"] = slug;

    try {
      const response = await axiosInstance.post('https://rentermmc.com:8443/api/Store/update-store', data);

      if (response.status === 200) {
        console.log('Data submitted successfully');
        navigate('/');
      } else {
        console.error('Post request failed with status:', response.status);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };



  // Handle file input change and upload
  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    try {
      const uploadedImageUrl = await uploadFile(selectedFile);
      setStoreLogo(uploadedImageUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };



  // Upload the file to the server
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      const response = await fetch(
        "https://rentermmc.com:8443/api/Store/add-image",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      const uploadedImageUrl = data.data;
      console.log(data.messages)

      if(data.isSuccessful){
        console.log("File uploaded successfully");
        return uploadedImageUrl;
      }else{
        console.log(data.isSuccessful)

        toast.error(data.messages);
      }
     
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleDeleteImage = (imageUrl) => {
    setStoreLogo(null)
  };




  return (
    <div className="container_m">
    <div className="container_n">
    <Meta title="Bines hesabı Renter MMC "/>

    <div className="col-12">
      <div className="row">
          <UserDetails />
          <div className="col-12 col-lg-9 my-3 px-2">
          <div className="details-description">

            <div className="row p-3 profile-details-title">
              Şəxsi Məlumatlarım
            </div>
            <form  onSubmit={handleFormSubmit}>
                <div className="row p-3 profile-details-inner d-flex">
                  <div className="col-12 col-md-6">
                    <div className="position-relative">
                      <label for="emailadress" className="login-labels">
                      Biznes obyektinin adı*
                      </label>
                      <br></br>
                      <input
                        type="text"
                        className="login-email"
                        id="emailadress"
                        placeholder=""
                        name="storeTitle"
                        value={storeTitle}
                        onChange={(e) => setStoreTitle(e.target.value)}

                      />
                    </div>
                    <div className="position-relative">
                      <label for="emailadress" className="login-labels">
                      Sloqan*
                      </label>
                      <br></br>
                      <input
                        type="text"
                        className="login-email"
                        id="emailadress"
                        placeholder=""
                        name="storeSlogan"
                        value={storeSlogan}
                        onChange={(e) => setStoreSlogan(e.target.value)}

                      />
                    </div>
                    <div className="position-relative">
                      <label for="password" className="login-labels">
                      Biznesiniz haqqında məlumat*
                      </label>
                      <br></br>
                      
                      <textarea
                        type="text"
                        className="textareaProfile"
                        id="password"
                        name="storeDescription" value={storeDescription}
                        onChange={(e) => setStoreDescription(e.target.value)}

                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
              
                      <div className="position-relative">
                        <label for="emailadress" className="login-labels">
                        Ünvan*
                        </label>
                        <br></br>
                        <input
                          type="text"
                          className="login-email"
                          id="emailadress"
                          placeholder=""
                          name="storeAddress"
                          value={storeAddress}
                          onChange={(e) => setStoreAddress(e.target.value)}

                        />
                        <Location  className="position-absolute icon-place" />
                      </div>
                      <div className="position-relative">
                        <label for="emailadress" className="login-labels">
                          Əlaqə nömrəsi*
                        </label>
                        <br></br>
                        <input
                          type="text"
                          className="login-email"
                          id="phone"
                          name="storeContact"
                          value={storeContact}
                          onChange={(e) => setStoreContact(e.target.value)}
                        />
                        <Call className="position-absolute  icon-place" />
                      </div>
                      <div className="position-relative">
                        <label for="emailadress" className="login-labels">
                        İş saatları(Aralıq)*
                        </label>
                        <br></br>
                        <input
                          type="text"
                          className="login-email"
                          id="phone"
                          name="workHours"
                          value={workHours}
                          onChange={(e) => setWorkHours(e.target.value)}

                        />
                        <Clock className="position-absolute  icon-place" />
                      </div>
                      <div className="position-relative">
                        <label for="emailadress" className="login-labels">
                        Biznes loqonuzu əlavə edin*
                        </label>
                        <br></br>
                       <input type="file"  onChange={handleFileChange}></input>






                       <div className="row mt-3" id="image-container">
                       {loading && (
      <>
        Şəkil Yüklənir...
      </>
  )}
  {storeLogo !== null && (



    <div className="col-12 col-md-12">
      <div className="img_upload_box text-center">

        <img
          src={storeLogo}
          key={storeLogo}
          className="img_upload_box_img"
        />
        <div className="img_upload_box_delete">
          <button
            type="button"
            className="btn btn-danger mt-2"
            onClick={() => handleDeleteImage(storeLogo)}
          >
            <BsTrash />
          </button>
        </div>
      </div>
    </div>
  )}


</div>



                      </div>
                      <div className="d-flex w-100 justify-content-end align-items-center">
                <button className="btn btn-primary" type="submit">Yenilə</button>
                    </div>
                    </div>
                    
                  </div>
                
              </form>
          </div>
        </div>
      </div>
    </div>
    </div>
        </div>
  );
};

export default Bizneshesab;
