import React from "react";
import NavFooter from "../components/NavFooter";
import Meta from "../components/Meta";

const Faq = () => {
  return (
    <>
            <NavFooter />
            <Meta title="Tez-Tez verilən suallar Renter MMC"/>

    <div className="container_m">
      <div className="container_n">

        <div className="row">
          <div className="col-12">  <main class="faq-content">
            <div className='nav-name d-flex text-left'>FAQ</div>


            <div class="faq-articles">

              <article class="faq-accordion">

                <input type="checkbox" class="tgg-title" id="tgg-title-1" />

                <div class="faq-accordion-title">
                  <label for="tgg-title-1">
                    <h2>Elanı necə silim ? </h2>
                    <span class="faq-arrow">
                    </span>
                  </label>
                </div>

                <div class="faq-accordion-content">
                  <p>Bunun üçün silmək istədiyiniz elanı tapın. Elanı tapmağın ən asan yolu elan kodunu axtarış xanası ilə axtarmaqdır. Elanı tapdıqdan sonra elanı sil düyməsinə tıklayın</p>
                </div>

              </article>

              <article class="faq-accordion">

                <input type="checkbox" class="tgg-title" id="tgg-title-2" />

                <div class="faq-accordion-title">
                  <label for="tgg-title-2">
                    <h2>
                      Niyə elanım silindi ?
                    </h2>
                    <span class="faq-arrow">
                    </span>
                  </label>
                </div>

                <div class="faq-accordion-content">
                  <p>Elan yerləşdirilən zaman qaydalar yazılıb, onları mütləq oxuyun. Yazılan qaydalardan hır hansı 1-i pozulsa sizin elan silinəcək.</p>
                </div>

              </article>

              <article class="faq-accordion">

                <input class="tgg-title" type="checkbox" id="tgg-title-3" />

                <div class="faq-accordion-title">
                  <label for="tgg-title-3">
                    <h2>
                      Elandakı məlumatlari necə dəyişim ?
                    </h2>
                    <span class="faq-arrow">
                    </span>
                  </label>
                </div>

                <div class="faq-accordion-content">
                  <p>Bunun üçün dəyişmək istadiyiniz elanı tapın. Elanı tapmağın ən asan yolu elan kodunu axtarış xanası ilə axtarmaqdır. Elanı tapdıqdan sonra "Düzəliş et" düyməsinə tıklayın. Elanı öz hesabınıza daxil olmaqla dəyişə bilərsiniz.  </p>
                </div>

              </article>



            </div>

          </main> </div>
        </div>
      </div>


    </div>
    </>
  );
};

export default Faq;
