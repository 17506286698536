export const setUser = (userData) => ({
    type: "SET_USER",
    payload: userData,
  });
  
  export const setCategoryData = (newCategoryData) => {
    return {
      type: 'SET_CAT_DATA',
      payload: newCategoryData,
    };
  };

  export const setHomeProductsData = (productData) => {
    return {
      type: 'SET_HOME_PRODUCTS_DATA',
      payload: productData,
    };
  };

  
  export const setHomeScroll = (homeScroll) => {
    return {
      type: 'SET_HOME_SCROLL_DATA',
      payload: homeScroll,
    };
  };