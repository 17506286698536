import React from 'react'
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
            <div class="alert alert-success m-5" role="alert">
                Ödənişiniz uğurla tamamlandı   
            </div>
            <Link to="/profile-details" className='primary_btn mb-5'>
                Profilə daxil ol
            </Link>
       </div>
      </div>
    </div>
    </>
  )
}

export default About