import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FaBars } from "react-icons/fa"; 
const UserDetails = () => {

  const navigate = useNavigate();

  const showLogoutConfirmation = () => {
    confirmAlert({
      title: 'Çıxış et',
      message: 'Profilinizdən çıxış etmək istəyirsiniz?',
      buttons: [
        {
          label: 'Bəli',
          onClick: () => navigate('/logout'),
        },
        {
          label: 'Xeyr',
          onClick: () => { },
        },
      ],
    });
  };


  return (
    <>    <div className="col-12 col-md-12 col-lg-3 my-3">
      <div className="details-categories">


        <div className='col-12 text-center'>
          <div className='row'>

          <div className='col-3 d-lg-none p-3 d-flex align-items-center justify-content-center'>
              <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMenu" aria-expanded="false" aria-controls="collapseMenu">
                <FaBars />
              </button>
            </div>

            <div className='col-6 d-lg-none p-3 d-flex align-items-center justify-content-center'>
              <b>Şəxsi kabinet</b>
            </div>
            <div className='col-3 d-lg-none p-3 d-flex align-items-center justify-content-center'>
              <button class="btn btn-light" type="button"onClick={() => showLogoutConfirmation()} >
                Çıxış et
              </button>
            </div>
          </div>
        </div>

        <ul class="list-group d-lg-block collapse mt-2" id="collapseMenu">
          <li><NavLink to="/profile-details">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M11.25 9.75C11.25 13.4715 14.2785 16.5 18 16.5C21.7215 16.5 24.75 13.4715 24.75 9.75C24.75 6.0285 21.7215 3 18 3C14.2785 3 11.25 6.0285 11.25 9.75ZM30 31.5H31.5V30C31.5 24.2115 26.7885 19.5 21 19.5H15C9.21 19.5 4.5 24.2115 4.5 30V31.5H30Z"
                fill="#787878"
              />
            </svg>
            Şəxsi məlumatlarım
          </NavLink></li>
          <hr />
          <li><NavLink to="/myproducts">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 34 33" fill="none">
              <path d="M33.25 1.5V1H32.75H1.25H0.75V1.5V31.25V31.75H1.25H32.75H33.25V31.25V1.5ZM25.75 25.1389H26.25V24.6389V22.9861V22.4861H25.75H8.25H7.75V22.9861V24.6389V25.1389H8.25H25.75ZM25.75 21.8333H26.25V21.3333V19.6806V19.1806H25.75H8.25H7.75V19.6806V21.3333V21.8333H8.25H25.75ZM25.75 16.875H26.25V16.375V8.11111V7.61111H25.75H8.25H7.75V8.11111V16.375V16.875H8.25H25.75ZM30.5 3.65278V29.0972H3.5V3.65278H30.5Z" fill="#787878" stroke="#787878" />
            </svg>
            Elanlarım
          </NavLink></li>{" "}
          <hr />
          <li><NavLink to="/myvipproducts">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              viewBox="0 0 31 33"
              fill="none"
            >
              <g clip-path="url(#clip0_328_2329)">
                <path
                  d="M6.29056 4.12366H24.6903C24.894 4.12363 25.0948 4.17492 25.2763 4.27335C25.4578 4.37177 25.6149 4.51454 25.7346 4.68997L30.6696 11.9146C30.7573 12.0432 30.8005 12.2001 30.7918 12.3588C30.7831 12.5174 30.723 12.6679 30.6218 12.7847L15.9642 29.6931C15.9038 29.7626 15.8305 29.8181 15.749 29.8561C15.6674 29.894 15.5794 29.9136 15.4904 29.9136C15.4015 29.9136 15.3135 29.894 15.2319 29.8561C15.1504 29.8181 15.0771 29.7626 15.0167 29.6931L0.359096 12.7861C0.257572 12.6692 0.197308 12.5184 0.188597 12.3595C0.179886 12.2005 0.223269 12.0433 0.311335 11.9146L5.24626 4.68997C5.36602 4.51454 5.52307 4.37177 5.70457 4.27335C5.88608 4.17492 6.08687 4.12363 6.29056 4.12366Z"
                  fill="#787878"
                />
              </g>
              <defs>
                <clipPath id="clip0_328_2329">
                  <rect width="30.9804" height="32.9893" fill="white" />
                </clipPath>
              </defs>
            </svg>
            VIP elanlarım
          </NavLink></li>{" "}
          <hr />
          <li><NavLink activeClassName="active-link" to="/abunelikler">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M26.6667 29.4533L29.9333 31.44L29.0667 27.6933L32 25.1867L28.1467 24.8533L26.6667 21.3333L25.16 24.8533L21.3333 25.1867L24.24 27.6933L23.3333 31.44L26.6667 29.4533ZM18.7733 28H2.66667C1.26667 28 0 26.7333 0 25.3333V6.66667C0 5.26667 1.26667 4 2.66667 4H29.3333C30.7333 4 32 5.26667 32 6.66667V20.7067C30.5357 19.3905 28.6356 18.6637 26.6667 18.6667C22.2533 18.6667 18.6667 22.2533 18.6667 26.6667C18.6667 27.12 18.7067 27.5733 18.7733 28ZM10.6667 18.5467C8 18.5467 2.66667 20 2.66667 22.6667V24H18.6667V22.6667C18.6667 20 13.3333 18.5467 10.6667 18.5467ZM10.6667 8C8.46667 8 6.66667 9.8 6.66667 12C6.66667 14.2 8.46667 16 10.6667 16C12.8667 16 14.6667 14.2 14.6667 12C14.6667 9.8 12.8667 8 10.6667 8ZM28 13.3333H18.6667V14.6667H28V13.3333ZM29.3333 10.6667H18.6667V12H29.3333V10.6667ZM29.3333 8H18.6667V9.33333H29.3333V8Z"
                fill="#787878"
              />
            </svg>
            Abunəliklərim
          </NavLink></li>{" "}
          <hr />
          <li><NavLink activeClassName="active-link" to="/notifications">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M18 33.75C19.0941 33.7489 20.1641 33.4291 21.0794 32.8298C21.9947 32.2304 22.7155 31.3774 23.1539 30.375H12.8461C13.2845 31.3774 14.0053 32.2304 14.9206 32.8298C15.8359 33.4291 16.9059 33.7489 18 33.75ZM28.125 20.25V15.994C28.125 11.0391 26.2013 6.72258 21.375 5.625L20.8125 2.25H15.1875L14.625 5.625C9.78188 6.72258 7.875 11.0222 7.875 15.994V20.25L4.5 24.75V28.125H31.5V24.75L28.125 20.25Z"
                fill="#787878"
              />
            </svg>
            Bildirişlərim
          </NavLink></li>{" "}
          <hr />
          <li><NavLink activeClassName="active-link" to="/business">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              viewBox="0 0 38 38"
              fill="none"
            >
              <path
                d="M30.4604 22.9675L24.5096 20.1289L21.9933 18.9285C23.0922 18.2362 23.9848 17.1676 24.5689 15.8805C25.0561 14.8075 25.3081 13.6427 25.308 12.4643C25.308 11.7701 25.1978 11.1108 25.0443 10.4747C24.2957 7.3921 21.8884 5.12122 18.9985 5.12122C16.1633 5.12122 13.7925 7.31116 12.9975 10.3059C12.814 10.993 12.6878 11.7074 12.6878 12.4643C12.6878 13.7788 12.9949 15.0043 13.5166 16.0702C14.1197 17.3067 15.0138 18.3262 16.1021 18.9817L13.6922 20.1039L7.54832 22.9653C7.0148 23.2153 6.66406 23.8172 6.66406 24.4894V31.2401C6.66406 32.1449 7.29448 32.8783 8.07272 32.8783H17.1977V27.7096C17.1989 27.6562 17.2145 27.6043 17.2429 27.5591L17.2391 27.5568L18.7336 24.9682L18.7374 24.9705C18.7906 24.8793 18.8852 24.8147 18.9985 24.8147C19.1117 24.8147 19.2063 24.8789 19.2595 24.9705L19.2611 24.9694L19.2747 24.9933C19.2766 24.9971 19.2793 25.0002 19.2808 25.004L20.7556 27.5579L20.7537 27.5591C20.7821 27.6044 20.7977 27.6565 20.7989 27.7099V32.8787H29.9239C30.7021 32.8787 31.3329 32.1453 31.3329 31.2405V24.4894C31.334 23.8195 30.989 23.2233 30.4604 22.9675ZM20.7548 21.2366L20.7586 21.2389L19.2637 23.8275L19.2599 23.8252C19.234 23.8718 19.1962 23.9108 19.1503 23.9381C19.1045 23.9654 19.0522 23.98 18.9989 23.9806C18.9455 23.98 18.8933 23.9654 18.8474 23.9381C18.8016 23.9108 18.7637 23.8718 18.7378 23.8252L18.7359 23.8263L18.7207 23.8001C18.7196 23.7975 18.7173 23.7952 18.7161 23.7925L17.2414 21.2378L17.2429 21.2366C17.2145 21.1913 17.1989 21.1392 17.1977 21.0858C17.1977 20.9167 17.3348 20.7795 17.5039 20.7795H20.493C20.6621 20.7795 20.7993 20.9167 20.7993 21.0858C20.7987 21.1392 20.7833 21.1914 20.7548 21.2366Z"
                fill="#787878"
              />
            </svg>
            Biznes hesabım
          </NavLink></li>
        </ul>
      </div>
    </div>
    </>
  )
}

export default UserDetails