import React from "react";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaEye,FaEyeSlash } from "react-icons/fa";
import Meta from "../components/Meta";


import { ReactComponent as Animationleft } from "../Images/animation-left.svg";
import { ReactComponent as Animationright } from "../Images/animation-right.svg";
import { ReactComponent as GoogleIcon } from "../Images/Google.svg";
import { ReactComponent as Logo } from "../Images/logo.svg";
import { ReactComponent as RenterMMc } from "../Images/rentermmc.svg";
import { ReactComponent as EllipseLeft } from "../Images/Ellipse-left.svg";
import { ReactComponent as EllipseRight } from "../Images/Ellipse-right.svg";
import { ReactComponent as CloseEye } from "../Images/closeeye.svg";
import bck_log_1 from "../Images/bckimages.png";
import bck_log_2 from "../Images/bckimage2.png";
import bck_log_3 from "../Images/bckimage3.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    loginRequest();
  };

  async function loginRequest() {
    try {
      const response = await fetch("https://rentermmc.com:8443/api/auth/forgot-password", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
            UserEmail: username,
        }),
      });

      if (response.ok) {
        const data = await response.json();
          toast.success("Şifrə yeniləmə linki elektron poçtunuza göndərildi");
          navigate("/actioninfo/forgetsuccess");
      } else {
        toast.error("Məlumatlarınız yalnışdır");
        throw new Error("error");
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className='login_register_walper'>
      <div className="login-page d-flex">
      <Meta title="Şifrə yenilə Renter MMC"/>

      <div className="col-md-6 only_desk login-left-side">
          <div className="d-flex align-items-center p-3">
            <Link to="/"><Logo /></Link>
            <div className="line"></div>
            <Link to="/"><RenterMMc /></Link>
          </div>
          <div className="row h-25">
            <div className="col-6 p-5 position-relative">
              <EllipseLeft className="position-absolute"  height={200}  />
              <img src={bck_log_1} alt="" height={200}  className="position-absolute" />
            </div>
            <div className="log-text col-6 d-flex flex-column justify-content-center">
              <span>Hər növ maşınların</span>
              <span className="text-white">Kirayəsi</span>
            </div>
          </div>
          <div className="row h-25">
            <div className="log-text col-6 d-flex flex-column justify-content-center">
              <span>Hər növ evlərin və obyektlərin</span>
              <span className="text-white">Kirayəsi</span>
            </div>
            <div className="col-6 p-5 position-relative">
              <EllipseRight className="position-absolute ellipse-right" height={200} />
              <img src={bck_log_2} alt="" height={200}  className="position-absolute" />
            </div>
          </div>
          <div className="row h-25">
            <div className="col-6 p-5 position-relative">
              <EllipseLeft className="position-absolute" height={200} />
              <img src={bck_log_3} alt="" height={200} className="position-absolute" />
            </div>
            <div className="log-text col-6 d-flex flex-column justify-content-center">
              <span>Hər növ geyimlərin</span>
              <span className="text-white">Kirayəsi</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 login-right-side">
          <div className="login-rightside-animations">
            <Animationleft />
            <Animationright />
          </div>
          <div className="login-page-brand d-flex justify-content-center">
            RENTER<div className="text-black">MMC</div>
          </div>
          <div className="login-details">

            <ToastContainer  /> 
            <div className="login-password">
              <form onSubmit={submitHandler}>
                <label for="emailadress" className="login-labels">
                  Elektron-poçt*
                </label>
                <br></br>
                <input
                  type="text"
                  className="login-email"
                  id="emailadress"
                  name="account-name"
                  value={username}
                  onChange={usernameHandler}
                />
                <br></br>
                <div className="d-flex justify-content-center">
                  <button className="login-submit" type="submit">
                    İrəli
                  </button>
                </div>
                <div className="d-flex justify-content-center mt-3 reset-password">
                Hesabınız var? <Link to="/login"><span>Daxil ol?</span></Link>
                </div>
                <div className="d-flex justify-content-center mt-3 reset-password">
                 Hesabınız yoxdur? <Link to="/register"><span>Qeydiyyat</span></Link>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
