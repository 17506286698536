import React, { useState } from 'react';
import {ReactComponent as Logo} from '../Images/logo.svg';
import {ReactComponent as RenterMMc} from '../Images/rentermmc.svg';
import {AiOutlineInstagram,AiOutlineTwitter} from 'react-icons/ai';
import {FaFacebook,FaTiktok} from 'react-icons/fa';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';

const Footer = () => {





  const [message, setMessage] = useState('');

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = 'https://rentermmc.com:8443/api/Report/add-general-report';
  
    const data = {
      message: message,
    };
  
    const jsonData = JSON.stringify(data);
  
    fetch(apiUrl, {
      method: 'POST',
      body: jsonData, 
      headers: {
        'Content-Type': 'application/json', 
      },
    })
      .then(response => response.json())
      .then(data => {
        setMessage("");
        toast.success("Mesajınız uğurla göndərildi");
      })
      .catch(error => {
        console.error('POST isteği sırasında xəta oluştu:', error);
      });
  }
  




  return (
  <>






<ToastContainer  /> 



  <div className='main-footer only_desk'>
  
    <div className='row p-5'>
      <div className='col-4 d-flex flex-column '>
           <div className='d-flex align-items-center p-3'>
                <Logo/>
                <div className='line'></div>
                <RenterMMc/>
            </div>
            <div className='text-white p-3 '>
              <small>RENTERMMC.COM lahiyəsi Azərbaycanda yalniz icarə üçün nəzərdə tutulmuş ümumi icarə platformasidir.

Hər bir istifadəçi RENTERMMC.COM saytindan istifadə etməklə 20 dən artiq kateqoriya üzrə məhsullari icariyə gotürə və axdara bilər.

RENTERMMC.COM-da şirkətlər və fərdi sahibkarlarla yanaşi fərdi şəxslərdə öz məhsullarini yerləşdirə bilərlər.</small></div>
            <div className='footer-links d-flex align-items-center p-3'>
            <Link to="https://instagram.com/rentermmc?igshid=OGQ5ZDc2ODk2ZA==" target="blank">
 <AiOutlineInstagram  className='text-white fs-2'/></Link>
 <Link to="https://www.tiktok.com/@rentermmc?_t=8hUaPa7PVH6&_r=1" target="blank">
              <FaTiktok className='text-white fs-4'/>
              </Link>
            </div>
      
      
      </div>
      <div className='col-4 p-4'>
        <span className='ul-name'>Ümumi</span>
        <div className='line-horizon'></div>
      <ul >
  <li><Link to='/about'><span class="arrow"></span>Haqqımızda</Link></li>
  <li><Link to='/rules'><span class="arrow"></span>Qaydalar</Link></li>
  <li><Link to='/term-condition'><span class="arrow"></span>İstifadəçi razılaşması</Link></li>
  <li><Link to='/privacy'><span class="arrow"></span>Məxfilik siyasəti</Link></li>
</ul>

      </div>
      <div className='col-4 p-4'>
      <span className='ul-name'>Bizə müraciət</span>
      <div className='line-horizon'></div>
      <div className='text-white w-75'>Fəaliyyətimizlə bağlı hər hansı irad və ya təklifiniz varsa, zəhmət olmazsa aşağıdakı formanı doldurun.</div>
      <div className='d-flex'>
      <form className="d-flex" onSubmit={handleSubmit}>
  <textarea
    className="mt-3 text-white"
    placeholder="Mesajınızı daxil edin..."
    style={{
      resize: 'none',
      width: '313px',
      height: '150px',
      borderRadius: '20px',
      border: '2px solid #FFF',
      backgroundColor: '#8A53FE',
      textIndent: '18px',
      color: 'white',
      padding : '15px 0',
      outline : 'none'
    }}
    required
    value={message}
    onChange={handleMessageChange}

  ></textarea>
  <br /><div className='d-flex align-items-end'>  <input className="d-flex" type="submit" name="submitInfo" value="Göndər" />
</div>
</form> </div>
      </div>
    </div>
  </div>
  <div className='footer-end only_desk'>
    <div >Saytın Administrasiyası reklam bannerlərinin və yerləşdirilmiş elanların məzmununa görə məsuliyyət daşımır.</div>
    <div>© 2022 RENTT MMC. Developed by <Link to='https://fraktal.az/'>Fraktal</Link></div>
  </div>

  
  
  </>
  )
}

export default Footer