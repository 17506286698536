import React from 'react'
import NavFooter from '../components/NavFooter'
import {ReactComponent as Call} from '../Images/phone.svg';
import {ReactComponent as Mail} from '../Images/mail.svg';
import {ReactComponent as Location} from '../Images/location.svg';
import Meta from '../components/Meta';


const Contact = () => {
  return (
<>

<NavFooter/>
<div className='container_m'>
<div className='container_n'>
<Meta title="Əlaqə Renter MMC"/>

<div className='contact-us'>
    <div className='row'>
        <div className='col-12'>
        <div className='nav-name d-flex text-left'>BİZİMLƏ ƏLAQƏ</div>
        <h2>Dəstək:</h2>
        <div className='d-flex align-items-center more-info p-2'><Call/>+994 77 613 59 59</div>
        <div className='d-flex align-items-center more-info p-2'><Call/>+994 70 613 59 59</div>
        <hr/><h2>Elektron-poçt:</h2>
        <div className='d-flex align-items-center more-info p-2'><Mail/>Info@rentermmc.com
        </div></div>
    </div>
</div></div>
</div>
</>
    )
}

export default Contact