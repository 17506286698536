import React, { useState, useEffect, useRef } from "react";
import UserDetails from "../components/UserDetails";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFillTelephoneFill, BsFillGridFill } from 'react-icons/bs';
import { FaEye,FaPen,FaPlus } from "react-icons/fa";
import Meta from "../components/Meta";


const Business = () => {
    const [storesItems, setStoresItems] = useState([]);


    useEffect(() => {
        StoreData();
    }, []);
      

        const StoreData = async () => {
          try {
            const response = await axiosInstance.get(
              `https://rentermmc.com:8443/api/Store/get-user-stores?languageCode=az`
            );
      
            const sdata = await response.data;
            setStoresItems(sdata.data);
            console.log(setStoresItems);
          } catch (error) {
            console.error('Error fetching data:', error);
          } 
        };
      

  return (
    <div className="container_m">
    <div className="container_n">
    <Meta title="Biznes hesabı Renter MMC "/>

    <div className="col-12">
      <div className="row">
          <UserDetails />
          <div className="col-12 col-lg-9 my-3 px-2">
          <div className="details-description">

            <div className="row p-3">
                <div className="col-6 profile-details-title">
                    Biznes hesabım
                </div>
                <div className="col-6 profile-details-title text-end">
                    <Link to={'/businesscreate'} className="btn btn-primary">
                       <FaPlus/> Yeni
                    </Link>
                </div>
            </div>
            {storesItems.map((store) => (
              <div className='col-12' key={store.storeId}>
                <div className='card'>
   
                  <div className='row g-0'>
                    <div className='col-md-4'>
                      <img
                      height={200}
                        src={store.storeCover}
                        className='w-100 rounded-start'
                        alt='...'
                      />
                    </div>
                    <div className='col-md-7'>
                      <div className='card-body'>
                        <h5 className='card-title'>{store.storeTitle}</h5>
                        <p className='card-text'>{store.storeDescription}</p>
                        <p className='card-text'>
                          <small className='text-muted'>
                            <BsFillTelephoneFill />
                             {store.storePhones}
                            <BsFillGridFill className='ms-4' />
                            {store.productCount} Elan
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-1 text-center">
                    <Link to={`/spage/${store.storeSlug}`}>
                        <FaEye/>
                    </Link>
                    <br/>
                    <Link to={`/businessupdate/${store.storeSlug}`}>
                        <FaPen/>
                    </Link>
                    
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default Business;
