import React, { useState, useEffect } from 'react';
import UserDetails from "../components/UserDetails";
import axiosInstance from '../axiosInstance';
import { Link, useNavigate } from "react-router-dom";
import Meta from '../components/Meta';

const Abunelikler = () => {

  const [subscription, setSubscription] = useState([]);
  const [vipPro, setVipPro] = useState([]);
  const [vipType, setVipType] = useState("");



  useEffect(() => {
    subscriptionData();
    vipProData();
  }, []);







  const vipTypeHandler = (event) => {
    setVipType(event.target.value);
  };


  const submitHandler = (event) => {
    event.preventDefault();
    codeRequest();
  };

  async function codeRequest() {
    try {
      const response = await axiosInstance.get(`/api/Payment/payment?packet_code=${vipType}`);

      if (response) {
        const data = response.data;
        const redirectUrl = response.data.data.redirect_url;
        console.log(redirectUrl);
        window.location.href = redirectUrl;
      } else {
        throw new Error("error");
      }
    } catch (error) {
      console.log(error.message);
    }
  }














  const subscriptionData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Promotion/get-user-promotions?languageCode=az`
      );

      const data = response.data;
      setSubscription(data.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const vipProData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Payment/get-promotion-codes`
      );

      const data = response.data;
      setVipPro(data.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };









  return (
    <div className="container_m">
    <Meta title="Abunəlik Renter MMC "/>

      <div className="container_n">
        <div className="col-12">
          <div className="row">
            <UserDetails />
            <div className="col-12 col-lg-9 my-3 px-2">
              <div className="details-description">

                <div className='row my-2'>
                  <div className='col-6 profile-details-title'>Abunəliklərim</div>
                  <div className='col-6 text-end'>
                    <button type="button" className="btn btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Yeni VIP al
                    </button>

                    <Link to="/buy" className="btn btn-primary ms-2">
                      Yeni Paket al
                    </Link>


                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">VIP al</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">

                            <form onSubmit={submitHandler} className="d-flex flex-column gap-15">

                              <div className='my-2'>
                                <label>VIP seçin</label>
                                <select className='form-control' onChange={vipTypeHandler}>
                                  <option value="">Seçim edin</option>
                                  {vipPro.map((vip) => (
                                    <option value={vip.promotion_code}>{vip.promotionTitle} - gün [{vip.dayCount}] -{vip.calculatedPrice} Azn</option>

                                  ))}
                                </select>
                              </div>

                              <div className="d-flex justify-content-center my-2">
                                <button type="submit" className="btn btn-success w-75">
                                  Ödəniş et
                                </button>
                              </div>




                            </form>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-12 col-md-12">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      Paket məlumatları
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Elan növü</th>
                          <th scope="col">Aid olduğu paket</th>
                          <th scope="col">Aktivlik müddəti</th>
                          <th scope="col">Status</th>
                          <th scope="col">Tarix</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscription.map((subdata) => (

                          <tr>
                            <th scope="row">1</th>
                            <td> {subdata.promotionTitle}</td>
                            <td>{subdata.promotionPacketTitle}</td>
                            <td>{subdata.dayCount} gün</td>

                            <td><img src={subdata.status} height={20} /></td>
                            <td>{subdata.activatedDate} &nbsp; &nbsp;
                              {subdata.activatedTime}</td>

                          </tr>


                        ))}
                      </tbody>
                    </table>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abunelikler;
