import React from 'react'
import UserDetails from '../components/UserDetails'
import Meta from '../components/Meta'

const PaymentHistory = () => {
  return (
    <div className="container_m">
      <div className="container_n">
      <Meta title="Ödənişlərim Renter MMC"/>

        <div className="col-12">
          <div className="row">
            <UserDetails />
            <div className="col-12 col-lg-9 my-3 px-2">
              <div className="details-description">
                <div className="row p-3 profile-details-title">
                  Müddəti bitmiş elanlar
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentHistory