import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from '../components/ProductCard';
import Meta from "../components/Meta";
const Favorite = () => {

  const [likedProducts, setLikedProducts] = useState([]);
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const fetchFavoriteProducts = async () => {
    const Slugs = JSON.parse(localStorage.getItem('likedProducts'));

    try {
      console.log('Slugs:', Slugs);
      const response = await axios.post(
        'https://rentermmc.com:8443/api/Product/get-favorite-products',
        {
          languageCode: 'az',
          pageIndex: 0,
          productSlugs: Slugs
        },
        {
          headers: {
            'accept': 'text/plain',
            'Content-Type': 'application/json'
          }
        }
      );

      setFavoriteProducts(response.data.data.items);
    } catch (error) {
      console.error('Error fetching favorite products:', error);
    }
  };


  useEffect(() => {
    fetchFavoriteProducts();
  }, [likedProducts]);

  useEffect(() => {
    const storedLikedProducts = JSON.parse(localStorage.getItem('likedProducts'));
    if (storedLikedProducts) {
      setLikedProducts(storedLikedProducts);
    }
  }, []);


  return (
    <div className='container_m'>
      <div className='container_n'>
      <Meta title="Seçilmişlər Renter MMC"/>

        <div className='row'>

          <div className='col-12 my-3'>
            <h3>Bəyəndiklərim</h3>
          </div>

          <div className='col-12 mb-5'>
            <ProductCard products={favoriteProducts} isLoading={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Favorite