import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { FaUser, FaBars, FaPlusCircle, FaTimesCircle, FaHeart, FaShoppingCart, FaGlobe, FaHome, FaThLarge, FaUserCircle, FaAngleDown, FaAngleRight, FaSearch } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as FacebookLogo } from "../Images/facebook.svg";
import { ReactComponent as InstagramLogo } from "../Images/instagram.svg";
import { ReactComponent as TiktokLogo } from "../Images/tiktok.svg";
import { ReactComponent as HeartGrey } from "../Images/heart-grey.svg";
import { ReactComponent as Login } from "../Images/login.svg";
import { ReactComponent as Logo } from "../Images/logo.svg";
import { ReactComponent as RenterMMc } from "../Images/rentermmc.svg";
import { ReactComponent as Filter } from "../Images/filter.svg";
import { ReactComponent as Premium } from "../Images/premium.svg";
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Category } from "../Images/categories.svg";
import { ReactComponent as Search } from "../Images/search.svg";
import axios from 'axios';


const CatNavSearch = (categoryId) => { window.location.href = `/search/?CategoryId=${categoryId}`; };

const CategoryItem = ({ category, isFirstLevel }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    if (!category.childCategories.length) {
      CatNavSearch(category.categoryId);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <li className="p-3" onClick={() => { if (!category.childCategories.length) { CatNavSearch(category.categoryId); } }}>

      <div className="d-flex justify-content-between align-items-center c_pointer" onClick={toggleExpansion}>
        <div className="d-flex align-items-center">
          {category.categoryImage && (
            <img src={category.categoryImage} alt={category.categoryTitle} className="me-2" height={30} />
          )}
          <span>{category.categoryTitle}</span>
        </div>
        {category.childCategories.length > 0 && (
          isExpanded ? <FaAngleDown /> : <FaAngleRight />
        )}
      </div>
      {isExpanded && category.childCategories.length > 0 && (
        <ul className="list-unstyled ms-3 ps-0">
          {category.childCategories.map((childCategory) => (
            <CategoryItem
              key={childCategory.categoryId}
              category={childCategory}
              isFirstLevel={false}
            />
          ))}
        </ul>
      )}
    </li>
  );
};


const CategoryList = ({ categories }) => {
  return (
    <ul className="mobile_cat_lists list-unstyled ps-0">
      {categories.map((category) => (
        <CategoryItem key={category.categoryId} category={category} />
      ))}
    </ul>
  );
};



const DeskCategory = ({ category }) => {
  return (
    <>
      <span>
        <img height={40} src={category.categoryImage} alt={category.categoryTitle} className="me-2" /> {category.categoryTitle}
      </span>
      {category.childCategories.length > 0 && (
        <FaAngleRight />
      )}
      {category.childCategories.length > 0 && (
        <ul className="child-categories">
          {category.childCategories.map((childCategory) => (
            <li className="d-flex justify-content-between align-items-center" key={childCategory.categoryId}
              onClick={() => { if (!childCategory.childCategories.length) { CatNavSearch(childCategory.categoryId); } }}>
              <DeskCategory category={childCategory} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const DeskCategoriesList = ({ data }) => {
  return (
    <div id="cssvmenu">
      <ul className="parent-menu">
        {data.map((category) => (
          <li key={category.categoryId} className="d-flex justify-content-between align-items-center"
            onClick={() => { if (!category.childCategories.length) { CatNavSearch(category.categoryId); } }}>
            <DeskCategory category={category} />
          </li>
        ))}
      </ul>
    </div>
  );
};







const Header = ({ categories, mobileCat, isFilterOpen,setCategoryData, toggleMobileCat,toggleFilter }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const userLocal = JSON.parse(localStorage.getItem("user"));
  const userFullName = userLocal ? userLocal.userFirstName + " " + userLocal.userLastName : "Giriş";
  const [paramValues, setParamValues] = useState([]);
  const [subParamValues, setSubParamValues] = useState();

  const [selectedCategory, setSelectedCategory] = useState("");

  const [isCategoryOpen, setisCategoryOpen] = useState(false);
  const [isAnnounceWithOpen, setIsAnnounceWithOpen] = useState(false);
  const [isAnnounceOpen, setIsAnnounceOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [sorts, setSorts] = useState([]);


  const location = useLocation();
  const initialKeyword = new URLSearchParams(location.search).get('keyword');
  const initialCity = new URLSearchParams(location.search).get('city');
  const initialSort = new URLSearchParams(location.search).get('sortType');

  const [getKeyword, setGetKeyword] = useState(initialKeyword || '');
  const [getCity, setGetCity] = useState(initialCity || '');
  const [getSort, setGetSort] = useState(initialSort || '');

  const handleKeywordChange = (event) => {
    setGetKeyword(event.target.value);
  };
  const handleCityChange = (event) => {
    setGetCity(event.target.value);
  };
  const handleSortChange = (event) => {
    setGetSort(event.target.value);
  };





  const toggleCategory = () => { setisCategoryOpen(!isCategoryOpen) }
  const toggleAnnounceWith = () => setIsAnnounceWithOpen(!isAnnounceWithOpen);
  const toggleAnnounce = () => setIsAnnounceOpen(!isAnnounceOpen);

  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleSidebarNav = (Nav) => {
    setSidebarOpen(!sidebarOpen);
    navigate(Nav)
  };

  useEffect(() => {
    fetchCategories();
    siteData();
    citiesData();
    sortsData();
  }, []);


  //Elements Data Start *************************************************************************************

  const siteData = async () => {
    try {
      const response = await fetch(
        `https://rentermmc.com:8443/api/Site/get-site-logo?languageId=az`
      );
      const data = await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const citiesData = async () => {
    try {
      const response = await axios.get(
        'https://rentermmc.com:8443/api/Category/get-parameter?LanguageCode=az&ParameterKey=city'
      );
      const data = response.data;
      setCities(data.data);
    } catch (error) {
      console.error('Error fetching shortCuts items:', error);
    }
  };

  const sortsData = async () => {
    try {
      const response = await axios.get(
        'https://rentermmc.com:8443/api/sort/get-all-sort-types?LanguageCode=az'
      );
      const data = response.data;
      setSorts(data.data);
    } catch (error) {
      console.error('Error fetching shortCuts items:', error);
    }
  };



  //Elements Data Stop *************************************************************************************



  // Fetch Categories Data
  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://rentermmc.com:8443/api/Category/get-all-categories?LanguageCode=az"
      );
      const data = await response.json();
      setCategoryData(data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch Parameters Data
  const fetchParameters = async (categoryId) => {
    try {
      let fetchUrl =
        "https://rentermmc.com:8443/api/Category/get-parameters?LanguageCode=az&RequestFrontType=search";
      if (categoryId) {
        fetchUrl += `&CategoryId=${categoryId}`;
      }
      const response = await fetch(fetchUrl);
      const data = await response.json();
      setParamValues(data.data);
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };


  // Fetch Parameters Data
  const fetchSubParameters = async (parameterKey, mask) => {
    try {
      let fetchUrl = `https://rentermmc.com:8443/api/Category/get-child-masks?LanguageCode=az&ParameterKey=${parameterKey}&Mask=${mask}`;

      const response = await fetch(fetchUrl);
      const data = await response.json();
      setSubParamValues(data.data);
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    setSelectedCategory(selectedCategoryId);
    fetchParameters(selectedCategoryId);
  };

  const handleParameterChange = (event) => {
    const parameterKey = event.target.name;
    const mask = event.target.value;
    console.log('Hazirdi', parameterKey, mask)
    fetchSubParameters(parameterKey, mask);
  };



  useEffect(() => {
    if (subParamValues && subParamValues.parameterMasks) {
      const maskOptions = subParamValues.parameterMasks.map((mask) => mask.parameterMaskData);
      const selectElement = document.getElementById(subParamValues.parameterKey);
      selectElement.innerHTML = '';
  
      const defaultOption = document.createElement('option');
      defaultOption.value = '';
      defaultOption.textContent = `Seçim Edin`;
      selectElement.appendChild(defaultOption);
  
      maskOptions.forEach((mask) => {
        const option = document.createElement('option');
        option.value = mask;
        option.textContent = mask;
        selectElement.appendChild(option);
      });
    }
  }, [subParamValues]);
  







  // Render Category Options
  const renderCategoryOptions = (categories, level = 0) => {
    return categories.map((category) => {
      const indentation = "\u00A0".repeat(level * 3);
      if (category.childCategories.length > 0) {
        return (
          <React.Fragment key={category.categoryId}>
            <option className="bold-option" disabled>
              {indentation} {category.categoryTitle} &#11167;
            </option>
            {renderCategoryOptions(category.childCategories, level + 1)}
          </React.Fragment>
        );
      } else {
        return (
          <option value={category.categoryId} key={category.categoryId}>
            {indentation}
            {category.categoryTitle}
          </option>
        );
      }
    });
  };
  // Render Parameter Inputs
  const renderParameterInputs = () => {
    return paramValues.map((param, index) => {
      let input;

      if (param.parameterMasks !== null && param.parameterMasks.length > 0) {
        const options = param.parameterMasks.map((mask) => (
          <option key={mask.parameterMaskId} value={mask.parameterMaskData} data-key={mask.parameterMaskId}>
            {mask.parameterMaskData}
          </option>
        ));

        input = (
          <select className="form-control mb-2" id={param.parameterKey} name={param.parameterKey} key={param.parameterId} onChange={handleParameterChange}>
            <option value=""> {param.parameterTitle} seçin</option>
            {options}
          </select>
        );
      } else {
        const inputType = param.parameterTypeTitle === "integer" ? "number" : "text";

        input = (
          <input className="form-control mb-2" type={inputType} name={param.parameterKey} key={param.parameterId} placeholder={param.parameterTitle} />
        );
      }

      return (
        <React.Fragment key={index}>
          <div className="col-4 col-md-3 mt-3">{input}</div>
        </React.Fragment>
      );
    });
  };


  const renderSubParameterInputs = () => {
    if (!subParamValues) {
      return null;
    }

    let param = subParamValues;

    let input;

    if (param.parameterMasks !== null && param.parameterMasks !== undefined && param.parameterMasks.length > 0) {
      const options = param.parameterMasks.map((mask) => (
        <option key={mask.parameterMaskId} value={mask.parameterMaskData} data-key={mask.parameterMaskId}>
          {mask.parameterMaskData}
        </option>
      ));

      input = (
        <select className="form-control mb-2" name={param.parameterKey} key={param.parameterId}>
          <option value=""> {param.parameterTitle} seçin</option>
          {options}
        </select>
      );
    } else {
      const inputType = param.parameterTypeTitle === "integer" ? "number" : "text";

      input = (
        <input className="form-control mb-2" type={inputType} name={param.parameterKey} key={param.parameterId} placeholder={param.parameterTitle} />
      );
    }

    return (
      <div className="col-3 mt-3">
        {input}
      </div>
    );
  };




  return (
    <>

      <header className="header-top-strip">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
      </header>
      <header className="header-center d-none d-md-block">
        <div className="container_m">
          <div className="container_n">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <div className="contact-us">Dəstək: (077) 613-59-59</div>
                <div className="header-links">
                  <Link to="https://instagram.com/rentermmc?igshid=OGQ5ZDc2ODk2ZA==" target="blank">
                    <InstagramLogo />{" "}
                  </Link>
                  <Link to="https://www.tiktok.com/@rentermmc?_t=8hUaPa7PVH6&_r=1" target="blank">
                    <TiktokLogo />{" "}
                  </Link>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <div className="header-support">
                  <Link className="header_text_color" to='/contact'>Yardım</Link></div>
                <div className="header-lang-select">
                  <select name="cars" id="cars">
                    <option value="volvo">AZ</option>
                    <option value="saab">EN</option>
                    <option value="opel">RU</option>
                  </select>
                </div>
                <div className="header-favorites">
                  <Link to="/favorite">
                    <HeartGrey className="me-1" />
                    Bəyəndiklərim
                  </Link>
                </div>
                <div className="header-login">
                  {userLocal ? (

                    <div className="dropdown">
                      <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <Login className="me-1" />
                        {userFullName}
                      </div>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="/profile-details" className="dropdown-item">
                            Şəxsi kabinet
                          </a>
                        </li>
                        <li>
                          <a href="/logout" className="dropdown-item">
                            Çıxış et
                          </a>
                        </li>
                      </ul>
                    </div>

                  ) : (
                    <a href="/login">
                      <Login className="me-1" />
                      <span>Giriş</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


      {/* Mobile Side Bar Start */}
      <nav className="navbar navbar-expand-lg navbar-light bg_primary only_mobile h_80_px">
        <div className="container">
          <div className="col-12">
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                <FaBars onClick={toggleSidebar} className="color_w m_menu_icon" />
              </div>
              <div className="col-6 ">
                <Link to="/" className=" d-flex align-items-center">
                  <Logo className="col-4" />
                  <div className="col-8 mobile_logo_text">
                    <RenterMMc />
                  </div>
                </Link>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <FaPlusCircle className="color_w m_menu_icon" onClick={userLocal ? toggleAnnounce : toggleAnnounceWith} />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="sidebar_top"></div>
        <div className="sidebar_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-3 d-flex align-items-center">
              </div>
              <div className="col-6">
                <Link to="/" className=" d-flex align-items-center">
                  <Logo className="col-4" />
                  <div className="col-8 mobile_logo_text">
                    <RenterMMc />
                  </div>
                </Link>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end" onClick={toggleSidebar}>
                <FaTimesCircle className="color_w m_menu_icon me-2" />
              </div>
            </div>
          </div>
        </div>

        <ul className="list-unstyled sidebar_lists">
          {userLocal ? (
            <li onClick={() => toggleSidebarNav('/profile-details')}><FaUser /> Profil</li>

          ) : (
            <li onClick={() => toggleSidebarNav('/login')}><FaUser /> Giriş</li>
          )}

          <li onClick={() => toggleSidebarNav('/favorite')}>
            <FaHeart /> Bəyəndiklərim</li>
          <li onClick={() => toggleSidebarNav('/buy')}> <FaShoppingCart /> Paketlər </li>
          <li><FaGlobe /> Dil</li>
          <li className="bg_primary"> &nbsp;&nbsp;</li>
          <li onClick={() => toggleSidebarNav('/about')}> Haqqımızda</li>
          <li onClick={() => toggleSidebarNav('/contact')}> Bizimlə əlaqə</li>
          <li onClick={() => toggleSidebarNav('/rules')}> Qaydalar</li>
          <li onClick={() => toggleSidebarNav('/term-condition')}> İstifadəçi razılaşması</li>
          <li onClick={() => toggleSidebarNav('/privacy')}> Məxfilik siyasəti</li>
        </ul>
      </div>
      <div className={`overlay ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>
      {/* Mobile Side Bar Stop */}



      {/* Mobile Bottom Nav Start */}
      <div className="only_mobile">
        <nav class="mobile-bottom-nav ">
          <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
            <div class="mobile-bottom-nav__item-content text-center">
              <Link to="/">
                <FaHome className='h_1_5_rem' />
                Ana səhifə
              </Link>
            </div>
          </div>
          <div class="mobile-bottom-nav__item">
            <div class="mobile-bottom-nav__item-content" onClick={toggleMobileCat}>
              <FaThLarge className='h_1_5_rem' />
              Kateqoriyalar
            </div>
          </div>
          <div class="mobile-bottom-nav__item">
            <div class="mobile-bottom-nav__item-content">
              <FaPlusCircle className='mobi-bottom-add-btn' onClick={userLocal ? toggleAnnounce : toggleAnnounceWith} />
            </div>
          </div>
          <div class="mobile-bottom-nav__item">
            <div class="mobile-bottom-nav__item-content">
              <Link to="/favorite">
                <FaHeart className='h_1_5_rem' />
                Bəyəndiklərim
              </Link>
            </div>
          </div>
          <div class="mobile-bottom-nav__item">
            <div class="mobile-bottom-nav__item-content">

              {userLocal ? (
                <Link to="/profile-details">
                  <FaUserCircle className='h_1_5_rem' />
                  <span>Profil</span>
                </Link>

              ) : (
                <Link to="/login">
                  <Login className="me-1" />
                  <span>Giriş</span>
                </Link>
              )}

            </div>
          </div>
        </nav>
      </div>
      {/* Mobile Bottom Nav Stop */}


      {/* Mobile Category Div Start */}
      <div className={`mobile_cat ${mobileCat ? 'open' : ''}`}>
        <div className="mobile_cat_top"></div>
        <div className="mobile_cat_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-3 d-flex align-items-center" onClick={toggleMobileCat}>
                <FaTimesCircle className="color_w m_menu_icon ms-3" />
              </div>
              <div className="col-6 d-flex align-items-center justify-content-center color_w">
                <b>Kateqoriyalar</b>
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        <CategoryList categories={categories} />
      </div>
      {/* Mobile Category Div Stop */}


      <header className="header-main  only_desk ">
        <div className="container_m">
          <div className="container_n">
            <div className="row d-flex">
              <div className="col-2">
                <Link to="/" className="d-flex align-items-center">
                  <Logo className="w-50" />

                  <div className="line"></div>
                  <RenterMMc />
                </Link>
              </div>
              <div className="col-6 search-menu">
                <form method="get" action={`/search`} className="col-lg-12">
                  <div className="input-group">
                    <div className="header_search-categories d-flex align-items-center category-toggle" onClick={toggleCategory} >
                      <Category /> Kateqoriyalar
                    </div>
                    <select className='header_search_select' name='city' value={getCity} onChange={handleCityChange}>
                      <option value="">Şəhər</option>
                      {cities && cities.parameterMasks && cities.parameterMasks.map((city) => (
                        <option value={city.parameterMaskData} selected={city.parameterMaskData === getCity}>{city.parameterMaskData}</option>
                      ))}
                    </select>
                    <input type="text" className="header_search_input" aria-describedby="basic-addon2" name='keyword' value={getKeyword} onChange={handleKeywordChange} />
                    <button type="submit" className="header_search_btn text-center" id="basic-addon2">
                      <Search />
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-4 packages">
                <div role="button" className="header-filter" onClick={toggleFilter}>
                  <Filter /> Filter
                </div>
                <div className="header-package">
                  <Link to="/buy">
                    <Premium /> Paket al
                  </Link>
                </div>
                <div className="header-new-announcement c_pointer" onClick={userLocal ? toggleAnnounce : toggleAnnounceWith}>
                  <Plus /> Yeni elan
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <div className={`modal ${isAnnounceOpen ? "d-block" : "d-none"}`}>
        <div className="modal-dialog modal-lg mt-5 p-2" role="document">
          <div className="modal-content p-3">
            <div className="modal-header border-0 p-0">
              <h2 className="modal-title text-black w-100 text-center">Elan Növü</h2>
              <button type="button" className="close modal_close_btn fs-1" data-dismiss="modal" aria-label="Close" onClick={toggleAnnounce}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-black p-0 mt-3 w-100 text-center">
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-6 col-md-3">
                    <Link to="/new-announcement" onClick={toggleAnnounce} className="modal_content_btn">
                      Fərdi
                    </Link>
                  </div>
                  <div className="col-6 col-md-3">
                    <Link to="/new-announcement" onClick={toggleAnnounce} className="modal_content_btn">
                      Biznesmen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 p-0">
              <div className="modal_content_alert_warning d-flex mt-3">
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" viewBox="0 0 34 38" fill="none">
                    <path d="M19.6504 25.1982H14.3491L12.2188 1.1875H21.7812L19.6504 25.1982Z" fill="#7E7346" />
                    <path d="M16.9997 36.8125C19.248 36.8125 21.0706 34.7799 21.0706 32.2727C21.0706 29.7654 19.248 27.7328 16.9997 27.7328C14.7513 27.7328 12.9287 29.7654 12.9287 32.2727C12.9287 34.7799 14.7513 36.8125 16.9997 36.8125Z" fill="#7E7346" />
                  </svg>
                </div>
                <div>
                  Əgər elanınızı biznesmen olaraq yaratmaq istəyirsinizsə, ilk öncə profil məlumatlarınıza biznesiniz haqqında lazım olan məlumatları əlavə edərək qeydiyyatınızı tamamlayın. <Link to="/biznes-hesab" onClick={toggleAnnounce}><span style={{ color: "#20B4F7" }}>Profil.az</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className={`modal ${isAnnounceWithOpen ? "d-block" : "d-none"}`}>
        <div className="modal-dialog modal-lg mt-5 p-2" role="document">
          <div className="modal-content p-3">
            <div className="modal-header border-0 p-0">
              <h2 className="modal-title text-black">MƏLUMAT</h2>
              <button type="button" className="close modal_close_btn fs-1" data-dismiss="modal" aria-label="Close" onClick={toggleAnnounceWith}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-black p-0 mt-3">
              <p>
                Elan paylaşmaq üçün ilk öncə sistemdə qeydiyyatdan keçməlisiniz və ya profiliniz varsa profilinizə daxil olmalısınız.
              </p>
            </div>
            <div className="modal-footer border-0 p-0">
              <Link to="/register" className="btn primary_btn">
                Qeydiyyat
              </Link>
              <Link to="/login" className="btn primary_btn">
                Giriş
              </Link>
            </div>

          </div>
        </div>
      </div>


      {isFilterOpen && (
        <div className="fullscreen-menu">
          <div className="fullscreen-menu-overlay" onClick={toggleFilter}></div>
          <div className='container_n bg_white p-3 box_shadow_1 fullscreen-menu-content'>
            <form method="get" action={`/search`} >
              <div className='row'>
                <div className="col-4 col-md-3 mt-3">
                  <select name="CategoryId" className="form-select" onChange={handleCategoryChange} value={selectedCategory} required>
                    <option value="">Kateqoriya seçin</option>
                    {renderCategoryOptions(categories)}
                  </select>
                </div>
                {renderParameterInputs()}
                <div className="filter_bottom_div">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6 col-md-3"></div>
                      <div className="col-6 col-md-3"></div>
                      <div className="col-6 col-md-3 text-right">
                        <select className='form-control' name='sortType' value={getSort} onChange={handleSortChange}>
                          {sorts && sorts.map((sort) => (
                            <option value={sort.sortKey} selected={sort.sortKey === getSort}>{sort.sortTypeTitle}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6 col-md-3 text-right">
                        <button type="submit" className="primary_btn form-control">Elanları göstər</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}


      {isCategoryOpen && (
        <div className="fullscreen-menu">
          <div className="fullscreen-menu-overlay" onClick={toggleCategory}></div>
          <div className='container_n bg_white p-3 box_shadow_1 fullscreen-menu-content'>
            <div className='row'>
              <DeskCategoriesList data={categories} />
            </div>
          </div>
        </div>
      )}


    </>
  );
};


const mapStateToProps = state => {
  return {
    mobileCat: state.mobileCat,
    isFilterOpen: state.isFilterOpen,
    categories: state.categories,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileCat: () => dispatch({ type: 'TOGGLE_MOBILE_CAT' }),
    toggleFilter: () => dispatch({ type: 'TOGGLE_FILTER' }),
    setCategoryData: (newCategoryData) => dispatch({ type: 'SET_CAT_DATA', payload: newCategoryData }),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
