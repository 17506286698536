import React, { useState, useEffect } from 'react';
import UserDetails from '../components/UserDetails'
import axiosInstance from '../axiosInstance';
import Meta from '../components/Meta';

import { ReactComponent as Notification } from "../Images/notifications.svg";

const Notifications = () => {

  const [nots, setNots] = useState([]);

  const productStatusData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://rentermmc.com:8443/api/Notification/get-notifications`
      );
      const data = response.data;
      setNots(data.data);
    } catch (error) {
      console.error('Error fetching shortCuts items:', error);
    }
  };


  useEffect(() => {
    productStatusData()
  }, []);



  return (
    <div className="container_m">
      <div className="container_n">
      <Meta title="Bildirimlər Renter MMC"/>

        <div className="col-12">
          <div className="row">
            <UserDetails />
            <div className="col-12 col-lg-9 my-3 px-2">
              <div className="details-description">

                <div className="profile-details-title mb-3">
                  Bildirişlərim
                </div>
                <div className="d-flex flex-column notifications-box pe-2">


                  {nots.map((not) => (
                    <div class="alert alert-light d-flex align-items-center" role="alert" key={not.notificationId}>
                      <div className='text-center'> <Notification /> <br /> <small className='text-nowrap only_mobile'>{not.notificationDate}</small></div>
                      <div>
                        {not.notificationMessage}
                      </div>
                      <div className='text-center only_desk'><p className='text-nowrap'>{not.notificationDate}</p></div>
                    </div>
                  ))}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications