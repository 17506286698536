import React, { useState, useEffect } from "react";
import { AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ReactComponent as Friday } from "../Images/friday.svg";
import { ReactComponent as View } from "../Images/viewnumber.svg";
import { ReactComponent as StoreIcon } from "../Images/store_icon.svg";

const ProductCard = ({ products, borderColor }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

    const [likedProducts, setLikedProducts] = useState([]);
    
    useEffect(() => {
      const storedLikedProducts = localStorage.getItem("likedProducts");
      if (storedLikedProducts) {
        setLikedProducts(JSON.parse(storedLikedProducts));
      }
    }, []);
    const handleLike = (productId) => {
        let updatedLikedProducts;
    
        if (likedProducts.includes(productId)) {
          updatedLikedProducts = likedProducts.filter((id) => id !== productId);
        } else {
          updatedLikedProducts = [...likedProducts, productId];
        }
    
        setLikedProducts(updatedLikedProducts);
        localStorage.setItem("likedProducts", JSON.stringify(updatedLikedProducts));
      };
    
      if (!products) {
        return <div className="text-center m-5">Sizin sorğunuza uyğun elan tapılmadı</div>;
      }
  return (
    <>
     <div className='col-12 col-xs-12 col-md-12 col-lg-12'>
        <div className="row">
          {products.map((product,index) => (
            <div className="col-6 col-sm-6 col-xs-6 col-md-6 col-lg-3 col-xl-3" key={index} >
              <div className="product_card " style={{border:`${borderColor}`}}>

                <button className={`favourite heart_btn ${ likedProducts.includes(product.slug) ? "liked" : "" }`} onClick={() => handleLike(product.slug)} >
                  <AiFillHeart className="fs-2" color={ likedProducts.includes(product.slug) ? "red" : "black" } />
                </button>

                <div className="product_card_photo">
                  <Link to={`/product/${product.slug}`}  className="w_100_f w-100">
                    <img src={product.coverImage} alt="product" className="product_card_photo_img mouse w-100" />
                  </Link>

                  {product.storeIcon && (
                  <div class="p_s_container">
        <div class="p_s_circle">
           <img src={product.storeIcon} width={30} height={30}/>
        </div>
        <div class="p_s_line">
        {product.storeTypeName}
            <div class="p_s_triangle"></div>
        </div>
    </div>
     )}
                  <div className="promotions">
                  {product.promotionIcons.map((icon, index) => (
  icon && (
    <img src={icon} alt="promotion" className="promotion_icon" key={index} width={30} />
  )
))}

                  </div>
                </div>

                <div className="product_card_body">
                  <Link to={ `/product/${product.slug}`}  className="link w-100">
                    <div className="product_card_price d-flex justify-content-between align-items-center">
                      <div>{product.price}  <small> AZN</small> </div>
                      <div className="product-dates d-flex align-items-center"><Friday className="me-2"/>1 gün</div>
                    </div>
                    <div className="product_card_title  d-flex justify-content-between align-items-center">
                    {product.productTitle.length <= 15 ? product.productTitle : `${product.productTitle.slice(0, 15)}...`}
 <img height={30} src={product.paymentIcon}/>
                    </div>
                    <div className="product_card_created d-flex align-items-center justify-content-between"><div> {product.city}</div>{/*<div className="d-flex align-items-center"><View className="me-1"/> {product.viewCount}</div>*/}</div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductCard